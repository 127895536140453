<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="商品名称：">
                    <el-input v-model.trim="formInline.title" maxlength="60"  clearable placeholder="商品名称" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column width="200" prop="goods_info.product_id" label="商品ID" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="200" prop="goods_info.title" label="商品名称" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="80" prop="goods_info.cover" label="商品图片">
                <template #default="scope">
                    <el-image  :src="scope.row.goods_info.cover" :preview-src-list="[scope.row.goods_info.cover]" :preview-teleported="true" style="width: 30px;height: 30px" :onerror="defaultImg" ></el-image>
                </template>
            </el-table-column>
            <el-table-column width="130" prop="userinfo.nickname" label="昵称" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="userinfo.mobile" label="电话号码"></el-table-column>
            <el-table-column width="180" prop="create_datetime" label="下载时间"></el-table-column>
            <el-table-column width="120" prop="status_name" label="状态"></el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'audit')" v-show="hasPermission(this.$route.name,'Audit')" v-if="scope.row.status==1">审核</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Audit')" v-if="scope.row.status>1">详情</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <audit-module ref="cashoutauditModuleFlag" @refreshData="getData"></audit-module>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,hasPermission} from "@/utils/util";
    import {douyinUserjob} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import AuditModule from "./components/auditModule";
    export default {
        mixins: [lyMixins],
        components:{
            AuditModule,
            Pagination,
        },
        name:'dyUserJobManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                orderstatics:{
                    totalmoney: 0,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
                auditStatusList:[
                    {id:10,name:"待审核"},
                    {id:20,name:"已通过"},
                    {id:30,name:"未通过"},
                ],
                statusList:[
                    {id:0,name:"未提交"},
                    {id:1,name:"已提交"},
                    {id:2,name:"已完成"},
                    {id:3,name:"未完成"},
                ]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            addModule() {
                // this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='audit') {
                    this.$refs.cashoutauditModuleFlag.auditModuleFn(row,"审核")
                }
                else if(flag=='detail') {
                    this.$refs.cashoutauditModuleFlag.auditModuleFn(row,"详情")
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                douyinUserjob(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>