<!--
 * @Descripttion: 奖励加码设置
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.07.03
-->
<template>
	<div>
		<el-form label-position="top">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-form-item inline label="大于等于">
						<el-input-number v-model="dataList.gmv_amount" style="width: 100%" placeholder="GMV金额" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="奖励比例%">
						<el-input-number v-model="dataList.percent" style="width: 100%;" placeholder="百分比" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="最高奖励(元)">
						<el-input-number v-model="dataList.max_reward_amount" style="width: 100%;" placeholder="单位元" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script setup>
    import {ref, onMounted, watch,computed} from 'vue'

	const props = defineProps({
        modelValue: { type: [Object], default: {} },
		size: { type: String, default: "small" },
    })

	const emits = defineEmits(['update:modelValue'])

	const dataList = computed({
		get() {
			return props.modelValue
		},
		set(value) {
			emits('update:modelValue', value)
		}
    })

    function addField() {
		dataList.value = {
			max_reward_amount:"",
			percent:"",
			gmv_amount:"",
		}
	}
	onMounted(()=>{
		if(props.modelValue === undefined || props.modelValue === "" ||props.modelValue === null||props.modelValue === {}){
			addField()
		}
	})

	defineExpose({

	})
</script>

<style scoped>

</style>