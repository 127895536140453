<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="联系人：">
                    <el-input v-model.trim="formInline.name" maxlength="60"  clearable placeholder="联系人" @change="search" style="width:130px"></el-input>
                  </el-form-item>
                <el-form-item label="手机号：">
                    <el-input  v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:130px"></el-input>
                  </el-form-item>
                <el-form-item label="商品名称：">
                    <el-input  v-model.trim="formInline.goods_name" maxlength="60"  clearable placeholder="请输入" @change="search" style="width:130px"></el-input>
                  </el-form-item>
                <el-form-item label="提交时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight"  border :data="tableData" v-loading="loadingPage" ref="tableref" style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column width="70" prop="name" label="联系人"></el-table-column>
                <el-table-column width="120" prop="mobile" label="手机号"></el-table-column>
                <el-table-column width="120" prop="platform" label="合作平台"></el-table-column>
                <el-table-column width="200" prop="remark" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column width="200" prop="goods_link" label="商品链接" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="shop_name" label="店铺名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="180" prop="goods_name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="180" prop="goods_name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="130" prop="yusuan" label="合作预算" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="180" prop="create_datetime" label="提交时间"></el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {douyinBrandcooperate} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
        },
        name:'brandCooperateManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                defaultImg:require('../../assets/img/avatar.jpg'),
                formInline:{
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                timers:[],
                tableData:[]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            handleEdit(row,flag) {
                if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.getData()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                douyinBrandcooperate(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
        },
        created() {
            this.getData()
        },
        timers(val){
            if (val) {
                this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = ''
                this.formInline.endAt = ''
            }
            this.getData()
        },
    }
</script>
