<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" label-position="right" label-width="110px" v-model="formData">
                <div class="ly-title">
                    订单信息
                </div>
                <el-form-item label="订单编号：">
                    {{formData.order_id}}
                </el-form-item>
                <el-form-item label="支付时间：">
                    <span>{{formData.pay_success_time}}</span>
                </el-form-item>
                <el-form-item label="订单状态：">
                    <span>{{formData.flow_point_name}}</span>
                </el-form-item>
                <el-form-item label="订单金额：">
                    <span>{{(formData.total_pay_amount)/100}}</span>
                </el-form-item>
                <el-form-item label="预估佣金：">
                    <span>{{(formData.estimated_commission)/100}}</span>
                </el-form-item>
                <el-form-item label="结算佣金：">
                    <span>{{(formData.real_commission)/100}}</span>
                </el-form-item>
                <div class="ly-title">
                    商品信息
                </div>
                <el-table border size="small" :data="formData.goodsinfo">
                    <el-table-column label="商品ID" width="200" prop="product_id"></el-table-column>
                    <el-table-column label="标题" min-width="150" prop="product_name"></el-table-column>
                    <el-table-column label="图片" width="180" prop="product_img">
                        <template #default="scope">
                            <el-image  :src="scope.row.product_img" :preview-src-list="[scope.row.product_img]" :preview-teleported="true" style="width: 30px;height: 30px"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="数量" min-width="50" prop="item_num"></el-table-column>
                </el-table>
            </el-form>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "shoppingMallOrderDetail",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingPage:false,
                loadingTitle:"详情",
                formData:{
                    goodsinfo:[]
                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.formData = {
                    goodsinfo:[]
                }
                this.$emit('refreshData')
            },
            orderDetailFn(item) {
                this.dialogVisible=true
                this.formData=item
                this.formData.goodsinfo = [{
                    'product_id':item.product_id,
                    'product_name':item.product_name,
                    'product_img':item.product_img,
                    'item_num':item.item_num,
                }]
            },
        }
    }
</script>
<style lang="scss">
    .form-order{
        background: #f1f1f1;
        .el-form-item{
            margin-bottom: 10px;
            span{
                display: block;
            }
            .el-form-item__label{
                font-weight: bold;
            }
        }
        .el-table{
            width: 98%;
            margin-bottom: 20px;
            .price-show{
                display: flex;
                span{
                    margin-right: 5px;
                }
            }
        }
    }
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
</style>

