<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" title="审核" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="100px">
                <el-form-item label="用户昵称：">
                    {{tempdata.userinfo.nickname}}
                </el-form-item>
                <el-form-item label="用户电话：">
                    {{tempdata.userinfo.mobile}}
                </el-form-item>
                <el-form-item label="提交时间：">
                    {{tempdata.create_datetime}}
                </el-form-item>
                <el-form-item label="提交金额：">
                    {{tempdata.money}}
                </el-form-item>
                <el-form-item label="账户余额：">
                    {{tempdata.balance}}
                </el-form-item>
                <el-form-item label="审核：" prop="action">
                    <el-radio-group v-model="formData.action" >
                        <el-radio label="pass">审核通过</el-radio>
                        <el-radio label="deny">审核未通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="未通过原因：" prop="audit_remarks" v-if="formData.action==='deny'">
                    <el-input type="textarea" v-model.trim="formData.audit_remarks" maxlength="200" show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button type="primary" @click="submitData" :loading="loadingSave">提交</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {douyinCashoutPassDeny} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "auditModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                tempdata:"",
                formData:{
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                },
                rules:{
                    // action: [
                    //     {required: true, message: '请选择审核结果',trigger: 'blur'}
                    // ]
                },
                statusList:[
                    {id:'pass',name:"审核通过"},
                    {id:'deny',name:"审核未通过"},
                ]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                }
                this.$emit('refreshData')
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                this.formData.id = item.id
                this.tempdata = deepClone(item)
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.action === 'deny' && this.formData.audit_remarks === ""){
                            this.$message.success("未通过原因必填项")
                            return
                        }
                        this.loadingSave=true
                        douyinCashoutPassDeny(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

