import axios from 'axios';
import { reqExpost, ajaxGet, ajaxPost, ajaxDelete, ajaxPut, ajaxPatch, uploadImg, ajaxGetDetailByID } from './request';
import { url } from './url';

// 获取登录页的信息
export const login = params => ajaxPost({ url: `token/`, params })
// 获取验证码
export const getCaptcha = params => ajaxGet({ url: `captcha/`, params })
// 获取菜单
export const apiSystemWebRouter = params => ajaxGet({ url: `system/menu/web_router/`, params })
//系统监控
export const monitorGetSystemInfo = params => ajaxGet({ url: `monitor/getsysteminfo/`, params })
// 获取系统所有api列表
export const getSystemLyapiList = params => ajaxGet({ url: `lyapi.json`, params })

/**
*系统配置
 * */

// 系统配置
export const platformsettingsSysconfig = params => ajaxGet({ url: `platformsettings/sysconfig/`, params })
// 系统配置 -- 新增
export const platformsettingsSysconfigAdd = params => ajaxPost({ url: `platformsettings/sysconfig/`, params })
// 系统配置 -- 编辑
export const platformsettingsSysconfigEdit = params => ajaxPut({ url: `platformsettings/sysconfig/`, params })
// 系统配置 -- 删除
export const platformsettingsSysconfigDelete = params => ajaxDelete({ url: `platformsettings/sysconfig/`, params })
// 系统配置 -- 保存子项
export const platformsettingsSysconfigSavecontent = params => ajaxPut({ url: `platformsettings/sysconfig/save_content/`, params })

/**
*计划任务
 * */

// 计划任务
export const crontabPeriodictask = params => ajaxGet({ url: `crontab/periodictask/`, params })
// 计划任务 -- 新增
export const crontabPeriodictaskAdd = params => ajaxPost({ url: `crontab/periodictask/`, params })
// 计划任务 -- 编辑
export const crontabPeriodictaskEdit = params => ajaxPut({ url: `crontab/periodictask/`, params })
// 计划任务 -- 删除
export const crontabPeriodictaskDelete = params => ajaxDelete({ url: `crontab/periodictask/`, params })
// 计划任务 -- 开始/暂停
export const crontabPeriodictaskEnabled = params => ajaxPut({ url: `crontab/periodictask/enabled/`, params })
// 计划任务 获取本地所有tasks文件中的task任务方法
export const crontabPeriodictaskMethodlist = params => ajaxGet({ url: `crontab/periodictask/tasklist/`, params })

// 计划任务 获取任务执行结果
export const crontabTaskresult = params => ajaxGet({ url: `crontab/taskresult/`, params })


/**
*终端服务
 * */

// 终端服务
export const apiTerminal = params => ajaxGet({ url: `terminal/terminal/`, params })
// 终端服务 -- 新增
export const apiTerminalAdd = params => ajaxPost({ url: `terminal/terminal/`, params })
// 终端服务 -- 编辑
export const apiTerminalEdit = params => ajaxPut({ url: `terminal/terminal/`, params })
// 终端服务 -- 删除
export const apiTerminalDelete = params => ajaxDelete({ url: `terminal/terminal/`, params })

/**
*系统管理
 * */
// 部门管理列表
export const apiSystemDept = params => ajaxGet({ url: `system/dept/`, params })
// 部门管理列表 -- 新增部门
export const apiSystemDeptAdd = params => ajaxPost({ url: `system/dept/`, params })
// 部门管理列表 -- 编辑部门
export const apiSystemDeptEdit = params => ajaxPut({ url: `system/dept/`, params })
// 部门管理列表 -- 删除部门
export const apiSystemDeptDelete = params => ajaxDelete({ url: `system/dept/`, params })


// 菜单管理列表
export const apiSystemMenu = params => ajaxGet({ url: `system/menu/`, params })
// 菜单管理列表 -- 新增菜单
export const apiSystemMenuAdd = params => ajaxPost({ url: `system/menu/`, params })
// 菜单管理列表 -- 编辑菜单
export const apiSystemMenuEdit = params => ajaxPut({ url: `system/menu/`, params })
// 菜单管理列表 -- 删除菜单
export const apiSystemMenuDelete = params => ajaxDelete({ url: `system/menu/`, params })
//获取部门数据,获取菜单树
export const systemMenuTree = params => ajaxGet({ url: `system/menu_tree/`, params })
// 菜单管理 》 按钮列表(全部)
export const systemButton = params => ajaxGet({ url: `system/button/`, params })
// 菜单管理 》 按钮 编辑
export const systemButtonEdit = params => ajaxPut({ url: `system/button/`, params })
// 菜单管理 》 按钮 新增
export const systemButtonAdd = params => ajaxPost({ url: `system/button/`, params })
// 菜单管理 》 按钮 删除
export const systemButtonDelete = params => ajaxDelete({ url: `system/button/`, params })
// 菜单管理 》 按钮配置列表（针对单个菜单）
export const systemMenuButton = params => ajaxGet({ url: `system/menu_button/`, params })
// 菜单管理 》 按钮配置列表（针对单个菜单） 新增
export const systemMenuButtonAdd = params => ajaxPost({ url: `system/menu_button/`, params })
// 菜单管理 》 按钮配置列表（针对单个菜单） 编辑
export const systemMenuButtonEdit = params => ajaxPut({ url: `system/menu_button/`, params })
// 菜单管理 》 按钮配置列表（针对单个菜单） 删除
export const systemMenuButtonDelete = params => ajaxDelete({ url: `system/menu_button/`, params })



// 角色管理列表
export const apiSystemRole = params => ajaxGet({ url: `system/role/`, params })
// 角色管理列表-修改
export const apiSystemRoleEdit = params => ajaxPut({ url: `system/role/`, params })
// 角色管理列表 -- 新增角色
export const apiSystemRoleAdd = params => ajaxPost({ url: `system/role/`, params })
// 角色管理列表 -- 新增角色
export const apiSystemRoleDelete = params => ajaxDelete({ url: `system/role/`, params })
//获取部门数据,用于角色授权
export const apiSystemDeptTree = params => ajaxGet({ url: `system/dept_tree/`, params })
//通过角色id,获取菜单数据
export const apiSystemRoleIdToMenu = params => ajaxGet({ url: `system/role_id_to_menu/`, params })
export const apiSystemRoleIdToMenuid = (id) => ajaxGet({ url: `system/role_id_to_menu/` + id + '/' })

//权限管理
// 权限管理 -- 保存
export const apiPermissionSave = params => ajaxPut({ url: `system/permission/`, params })

//管理员管理
export const apiSystemUser = params => ajaxGet({ url: `system/user/`, params })
//管理员管理-新增
export const apiSystemUserAdd = params => ajaxPost({ url: `system/user/`, params })
//管理员管理-修改
export const apiSystemUserEdit = params => ajaxPut({ url: `system/user/`, params })
//管理员管理-删除
export const apiSystemUserDelte = params => ajaxDelete({ url: `system/user/`, params })

/**
 *日志管理
 * */
// 日志管理 查询
export const systemOperationlog = params => ajaxGet({ url: `system/operation_log/`, params })
// 日志管理 删除
export const systemOperationlogDelete = params => ajaxDelete({ url: `system/operation_log/`, params })
// 日志管理 清空全部日志
export const systemOperationlogDeletealllogsDelete = params => ajaxDelete({ url: `system/operation_log/deletealllogs/`, params })

/**
 *个人中心
 * */
// 获取当前个人用户信息
export const systemUserUserInfo = params => ajaxGet({ url: `system/user/user_info/`, params })
// 更新修改当前个人用户信息
export const systemUserUserInfoEdit = params => ajaxPut({ url: `system/user/user_info/`, params })
// 用户重置个人密码
export const systemUserChangePassword = params => ajaxPut({ url: `system/user/change_password/`, params })


/**
 *消息中心
 * */
//消息模板
export const messagesMessagetemplate = params => ajaxGet({ url: `messages/messagetemplate/`, params })
//消息模板-新增
export const messagesMessagetemplateAdd = params => ajaxPost({ url: `messages/messagetemplate/`, params })
//消息模板-修改
export const messagesMessagetemplateEdit = params => ajaxPut({ url: `messages/messagetemplate/`, params })
//消息模板-删除
export const messagesMessagetemplateDelete = params => ajaxDelete({ url: `messages/messagetemplate/`, params })

//消息公告
export const messagesMessagenotice = params => ajaxGet({ url: `messages/messagenotice/`, params })
//消息公告-新增
export const messagesMessagenoticeAdd = params => ajaxPost({ url: `messages/messagenotice/`, params })
//消息公告-修改
export const messagesMessagenoticeEdit = params => ajaxPut({ url: `messages/messagenotice/`, params })
//消息公告-删除
export const messagesMessagenoticeDelete = params => ajaxDelete({ url: `messages/messagenotice/`, params })



/**
 *省市区选择
 * */
// 省市区选择  获取省
export const getProvince = params => ajaxGet({ url: `areas/`, params })
// 省市区选择  获取市/区
export const getCityDistrictByID = params => ajaxGetDetailByID({ url: `areas/`, params })

// 省市区选择  根据详细地址获取经纬度
export const getAddressaccuracy = params => ajaxGet({ url: `getaddressaccuracy/`, params })


/**
 *意见反馈
 * */

// 意见反馈 列表
export const PlatformsettingsUserfeeckback = params => ajaxGet({ url: `platformsettings/userfeeckback/`, params })
// 意见反馈 新增
export const PlatformsettingsUserfeeckbackAdd = params => ajaxPost({ url: `platformsettings/userfeeckback/`, params })
// 意见反馈 编辑
export const PlatformsettingsUserfeeckbackEdit = params => ajaxPut({ url: `platformsettings/userfeeckback/`, params })
// 意见反馈 删除
export const PlatformsettingsUserfeeckbackDelete = params => ajaxDelete({ url: `platformsettings/userfeeckback/`, params })

/**
 *地区管理
 * */

// 地区管理列表
export const addressArea = params => ajaxGet({ url: `address/area/`, params })
// 地区管理列表 获取根地区
export const addressAreaRoot = params => ajaxGet({ url: `address/area/area_root/`, params })
// 地区管理列表 -- 新增
export const addressAreaAdd = params => ajaxPost({ url: `address/area/`, params })
// 地区管理列表 -- 编辑
export const addressAreaEdit = params => ajaxPut({ url: `address/area/`, params })
// 地区管理列表 -- 删除
export const addressAreaDelete = params => ajaxDelete({ url: `address/area/`, params })

/**
 *商城管理
 * */

// 商品分类 列表
export const mallGoodstype = params => ajaxGet({ url: `mall/goodstype/`, params })
// 商品分类 新增
export const mallGoodstypeAdd = params => ajaxPost({ url: `mall/goodstype/`, params })
// 商品分类 编辑
export const mallGoodstypeEdit = params => ajaxPut({ url: `mall/goodstype/`, params })
// 商品分类 删除
export const mallGoodstypeDelete = params => ajaxDelete({ url: `mall/goodstype/`, params })

// 商品管理 列表
export const mallGoodsspu = params => ajaxGet({ url: `mall/goodsspu/`, params })
// 商品管理 新增
export const mallGoodsspuAdd = params => ajaxPost({ url: `mall/goodsspu/`, params })
// 商品管理 编辑
export const mallGoodsspuEdit = params => ajaxPut({ url: `mall/goodsspu/`, params })
// 商品管理 删除
export const mallGoodsspuDelete = params => ajaxDelete({ url: `mall/goodsspu/`, params })

// 商品管理 上下架
export const mallGoodsspuIslaunched = params => ajaxPut({ url: `mall/goodsspu/islaunched/`, params })

// 商品管理 修改商品SKU价格
export const mallGoodsspueEditskups = params => ajaxPut({ url: `mall/goodsspu/editskups/`, params })

// 商城订单 列表
export const mallGoodsOrder = params => ajaxGet({ url: `mall/goodsorder/`, params })
// 商城订单 新增
export const mallGoodsOrderAdd = params => ajaxPost({ url: `mall/goodsorder/`, params })
// 商城订单 编辑
export const mallGoodsOrderEdit = params => ajaxPut({ url: `mall/goodsorder/`, params })
// 商城订单 删除
export const mallGoodsOrderDelete = params => ajaxDelete({ url: `mall/goodsorder/`, params })

// 商城订单 发货
export const mallGoodsSendoutgoods = params => ajaxPost({ url: `mall/goodsorder/sendoutgoods/`, params })

// 商城订单 订单价格 订单量统计
export const mallGoodsOrderstatistics = params => ajaxGet({ url: `mall/goodsorder/orderstatistics/`, params })

// 抖音订单 列表
export const douyinDyorder = params => ajaxGet({ url: `douyin/dyorder/`, params })


// 领样订单 列表
export const douyinSampleOrder = params => ajaxGet({ url: `douyin/sampleorder/`, params })
// 领样订单 删除
export const douyinSampleOrderDelete = params => ajaxDelete({ url: `douyin/sampleorder/`, params })

//营销管理

// 优惠券管理 列表
export const mallGoodscoupon = params => ajaxGet({ url: `mall/goodscoupon/`, params })
// 优惠券管理 新增
export const mallGoodscouponAdd = params => ajaxPost({ url: `mall/goodscoupon/`, params })
// 优惠券管理 编辑
export const mallGoodscouponEdit = params => ajaxPut({ url: `mall/goodscoupon/`, params })
// 优惠券管理 删除
export const mallGoodscouponDelete = params => ajaxDelete({ url: `mall/goodscoupon/`, params })

// 优惠券记录 列表
export const mallCouponrecord = params => ajaxGet({ url: `mall/couponrecord/`, params })
// 优惠券记录 新增
export const mallCouponrecordAdd = params => ajaxPost({ url: `mall/couponrecord/`, params })
// 优惠券记录 编辑
export const mallCouponrecordEdit = params => ajaxPut({ url: `mall/couponrecord/`, params })
// 优惠券记录 删除
export const mallCouponrecordDelete = params => ajaxDelete({ url: `mall/couponrecord/`, params })

/**
 * 财务统计
 * **/

//商品订单统计
export const mallGoodsforderinfo = params => ajaxGet({ url: `mall/goodsforderinfo/`, params })
// 商品订单统计 新增
export const mallGoodsforderinfoAdd = params => ajaxPost({ url: `mall/goodsforderinfo/`, params })
// 商品订单统计 编辑
export const mallGoodsforderinfoEdit = params => ajaxPut({ url: `mall/goodsforderinfo/`, params })
// 商品订单统计 删除
export const mallGoodsforderinfoDelete = params => ajaxDelete({ url: `mall/goodsforderinfo/`, params })
// 商品订单统计 总金额统计
export const mallGoodsforderinfoOrderstatistics = params => ajaxGet({ url: `mall/goodsforderinfo/orderstatistics/`, params })



/**
 *用户管理
 * */

// 用户管理 列表
export const UsersUsers = params => ajaxGet({ url: `users/users/`, params })
// 用户管理 新增
export const UsersUsersAdd = params => ajaxPost({ url: `users/users/`, params })
// 用户管理 编辑
export const UsersUsersEdit = params => ajaxPut({ url: `users/users/`, params })
// 用户管理 删除
export const UsersUsersDelete = params => ajaxDelete({ url: `users/users/`, params })
// 用户管理 禁用用户
export const UsersUsersdisableEdit = params => ajaxPut({ url: `users/users/disableuser/`, params })
// 用户管理 拉黑用户
export const UsersUsersBlack = params => ajaxPut({ url: `users/users/blackuser/`, params })
// 用户管理 获取用户抖音授权列表
export const UsersDouyinAccount = params => ajaxGet({ url: `users/users/getDouyinAccount/`, params })

// 用户管理 导出
export const UsersUsersExportexecl = params => ajaxGet({ url: `users/users/exportexecl/`, params })

/**
*平台设置
*/
//轮播图列表
export const platformsettingsLunboimg = params => ajaxGet({ url: `platformsettings/lunboimg/`, params })
// 轮播图列表 新增
export const platformsettingsLunboimgAdd = params => ajaxPost({ url: `platformsettings/lunboimg/`, params })
// 轮播图列表 编辑
export const platformsettingsLunboimgEdit = params => ajaxPut({ url: `platformsettings/lunboimg/`, params })
// 轮播图列表 删除
export const platformsettingsLunboimgDelete = params => ajaxDelete({ url: `platformsettings/lunboimg/`, params })

//icon列表
export const platformsettingsIconmanage = params => ajaxGet({ url: `platformsettings/iconmanage/`, params })
// icon列表 新增
export const platformsettingsIconmanageAdd = params => ajaxPost({ url: `platformsettings/iconmanage/`, params })
// icon列表 编辑
export const platformsettingsIconmanageEdit = params => ajaxPut({ url: `platformsettings/iconmanage/`, params })
// icon列表 删除
export const platformsettingsIconmanageDelete = params => ajaxDelete({ url: `platformsettings/iconmanage/`, params })

//达人/抖客列表
export const douyinSystemdareninfo = params => ajaxGet({ url: `douyin/systemdareninfo/`, params })
// 达人/抖客 新增
// export const douyinSystemdareninfoAdd= params => ajaxPost({url: `douyin/systemdareninfo/`,params})
// 达人/抖客 编辑
// export const douyinSystemdareninfoEdit= params => ajaxPut({url: `douyin/systemdareninfo/`,params})
// 达人/抖客 删除
export const douyinSystemdareninfoDelete = params => ajaxDelete({ url: `douyin/systemdareninfo/`, params })
//达人/抖客 获取抖音授权code链接
export const douyinSystemdareninfoCreateCode = params => ajaxPost({ url: `douyin/systemdareninfo/createCode/`, params })
//达人/抖客 刷新授权
export const douyinSystemdareninfoRefreshToken = params => ajaxPost({ url: `douyin/systemdareninfo/refreshToken/`, params })

export const douyinSystemdareninfoDK = params => ajaxGet({ url: `douyin/systemdareninfo/getCodeBuyinUserinfo/`, params })
export const douyinSystemdareninfoCreateCodeDK = params => ajaxPost({ url: `douyin/systemdareninfo/createCodeBuyin/`, params })


/**
*帮助中心
 * */

// 帮助中心
export const platformsettingHelpcenter = params => ajaxGet({ url: `platformsettings/helpcenter/`, params })
// 帮助中心 -- 新增
export const platformsettingHelpcenterAdd = params => ajaxPost({ url: `platformsettings/helpcenter/`, params })
// 帮助中心 -- 编辑
export const platformsettingHelpcenterEdit = params => ajaxPut({ url: `platformsettings/helpcenter/`, params })
// 帮助中心 -- 删除
export const platformsettingHelpcenterDelete = params => ajaxDelete({ url: `platformsettings/helpcenter/`, params })

// 帮助类型
export const platformsettingHelptype = params => ajaxGet({ url: `platformsettings/helptype/`, params })
// 帮助类型 -- 新增
export const platformsettingHelptypeAdd = params => ajaxPost({ url: `platformsettings/helptype/`, params })
// 帮助类型 -- 编辑
export const platformsettingHelptypeEdit = params => ajaxPut({ url: `platformsettings/helptype/`, params })
// 帮助类型 -- 删除
export const platformsettingHelptypeDelete = params => ajaxDelete({ url: `platformsettings/helptype/`, params })

//其他设置
export const platformsettingsOther = params => ajaxGet({ url: `platformsettings/other/`, params })
// 其他设置 新增
export const platformsettingsOtherAdd = params => ajaxPost({ url: `platformsettings/other/`, params })
// 其他设置 编辑
export const platformsettingsOtherEdit = params => ajaxPut({ url: `platformsettings/other/`, params })
// 其他设置 删除
export const platformsettingsOtherDelete = params => ajaxDelete({ url: `platformsettings/other/`, params })

// 平台设置 图片上传
export const platformsettingsUploadPlatformImg = params => uploadImg({ url: `platformsettings/uploadplatformimg/`, params })

//前端访问操作 获取
export const superOerateGet = params => ajaxGet({ url: `super/operate/`, params })
//前端访问操作 设置
export const superOerateSet = params => ajaxPost({ url: `super/operate/`, params })

/**
*赏金任务
 * */

//赏金任务
export const douyinAwardTask = params => ajaxGet({ url: `douyin/dyAwardTask/`, params })
// 赏金任务 禁用状态
export const douyinAwardTaskDisablestatus = params => ajaxPut({ url: `douyin/dyAwardTask/disablestatus/`, params })
// 赏金任务 修改排序
export const douyinAwardTaskEditsort = params => ajaxPut({ url: `douyin/dyAwardTask/editsort/`, params })
// 赏金任务 同步赏金任务
export const douyinAwardTaskSyncColonelGoods = params => ajaxPost({ url: `douyin/dyAwardTask/syncAwardTask/`, params })
// 赏金任务 编辑
export const douyinDyAwardTask = params => ajaxPut({ url: `douyin/dyAwardTask/`, params })

/**
*商品管理
 * */

//商品管理
export const douyinProductmanage = params => ajaxGet({ url: `douyin/productmanage/`, params })
// 商品管理 禁用状态
export const douyinProductmanageDisablestatus = params => ajaxPut({ url: `douyin/productmanage/disablestatus/`, params })
// 商品管理 禁用计算奖励
export const douyinProductmanageDisablecalcaward = params => ajaxPut({ url: `douyin/productmanage/disablecalcaward/`, params })
// 商品管理 修改排序
export const douyinProductmanageEditsort = params => ajaxPut({ url: `douyin/productmanage/editsort/`, params })
// 商品管理 同步团长活动商品
export const douyinProductmanageSyncColonelGoods = params => ajaxPost({ url: `douyin/productmanage/syncColonelGoods/`, params })
// 商品管理 修改商品属性
export const douyinProductmanageEditAttribute = params => ajaxPut({ url: `douyin/productmanage/editAttribute/`, params })

//商品分类
export const douyinProductcategory = params => ajaxGet({ url: `douyin/productcategory/`, params })
// 商品分类 新增
export const douyinProductcategoryAdd = params => ajaxPost({ url: `douyin/productcategory/`, params })
// 商品分类 编辑
export const douyinProductcategoryEdit = params => ajaxPut({ url: `douyin/productcategory/`, params })
// 商品分类 删除
export const douyinProductcategoryDelete = params => ajaxDelete({ url: `douyin/productcategory/`, params })
// 商品管理 禁用状态
export const douyinProductcategoryDisablestatus = params => ajaxPut({ url: `douyin/productcategory/disablestatus/`, params })

//店铺管理
export const douyinDyshop = params => ajaxGet({ url: `douyin/dyshop/`, params })
// 店铺管理 新增
export const douyinDyshopAdd = params => ajaxPost({ url: `douyin/dyshop/`, params })
// 店铺管理 编辑
export const douyinDyshopEdit = params => ajaxPut({ url: `douyin/dyshop/`, params })
// 店铺管理 删除
export const douyinDyshopDelete = params => ajaxDelete({ url: `douyin/dyshop/`, params })
// 商品管理 禁用状态
export const douyinDyshopDisablestatus = params => ajaxPut({ url: `douyin/dyshop/disablestatus/`, params })
// 商品管理 修改排序
export const douyinDyshopEditsort = params => ajaxPut({ url: `douyin/dyshop/editsort/`, params })
// 店铺商品列表
export const dyshopProduct = params => ajaxGet({ url: `douyin/dyshopProduct/`, params })
// 添加店铺商品
export const douyinDyshopProduct = params => ajaxPost({ url: `douyin/dyshopProduct/`, params })
// 修改店铺商品状态
export const dyshopProductDisablestatus = params => ajaxPut({ url: `douyin/dyshopProduct/disablestatus/`, params })
// 修改店铺商品排序
export const dyshopProductEditsort = params => ajaxPut({ url: `douyin/dyshopProduct/editsort/`, params })
// 删除店铺商品
export const DeldyshopProduct = params => ajaxDelete({ url: `douyin/dyshopProduct/${params}/`, })

/**
*营销管理
 * */

// 营销管理
export const douyinMarketingmanage = params => ajaxGet({ url: `douyin/marketingmanage/`, params })
// 营销管理 -- 新增
export const douyinMarketingmanageAdd = params => ajaxPost({ url: `douyin/marketingmanage/`, params })
// 营销管理 -- 编辑
export const douyinMarketingmanageEdit = params => ajaxPut({ url: `douyin/marketingmanage/`, params })
// 营销管理 -- 删除
export const douyinMarketingmanageDelete = params => ajaxDelete({ url: `douyin/marketingmanage/`, params })
// 营销管理 -- 保存子项
export const douyinMarketingmanageSavecontent = params => ajaxPut({ url: `douyin/marketingmanage/save_content/`, params })

// 营销管理
export const douyinMarketRewardRecord = params => ajaxGet({ url: `douyin/marketRewardRecord/`, params })

// 拉新商品
export const douyinYxlaxin = params => ajaxGet({ url: `douyin/yxlaxin/`, params })
// 拉新商品 -- 新增
export const douyinYxlaxinAdd = params => ajaxPost({ url: `douyin/yxlaxin/`, params })
// 拉新商品 -- 删除
export const douyinYxlaxinDelete = params => ajaxDelete({ url: `douyin/yxlaxin/`, params })
// 商品管理 禁用状态
export const douyinYxlaxinDisablestatus = params => ajaxPut({ url: `douyin/yxlaxin/disablestatus/`, params })
// 拉新商品 修改排序
export const douyinYxlaxinEditsort = params => ajaxPut({ url: `douyin/yxlaxin/editsort/`, params })

// 新人扶持
export const douyinYxxinrenFc = params => ajaxGet({ url: `douyin/yxxinrenFc/`, params })
// 新人扶持 -- 新增
export const douyinYxxinrenFcAdd = params => ajaxPost({ url: `douyin/yxxinrenFc/`, params })
// 新人扶持 -- 删除
export const douyinYxxinrenFcDelete = params => ajaxDelete({ url: `douyin/yxxinrenFc/`, params })
// 新人扶持 禁用状态
export const douyinYxxinrenFcDisablestatus = params => ajaxPut({ url: `douyin/yxxinrenFc/disablestatus/`, params })
// 新人扶持 修改排序
export const douyinYxxinrenFcEditsort = params => ajaxPut({ url: `douyin/yxxinrenFc/editsort/`, params })

// 0元购商品
export const douyinYx0yuan = params => ajaxGet({ url: `douyin/yx0yuan/`, params })
// 0元购商品 -- 新增
export const douyinYx0yuanAdd = params => ajaxPost({ url: `douyin/yx0yuan/`, params })
// 0元购商品 -- 删除
export const douyinYx0yuanDelete = params => ajaxDelete({ url: `douyin/yx0yuan/`, params })
// 0元购商品 禁用状态
export const douyinYx0yuanDisablestatus = params => ajaxPut({ url: `douyin/yx0yuan/disablestatus/`, params })
// 0元购商品 修改排序
export const douyinYx0yuanEditsort = params => ajaxPut({ url: `douyin/yx0yuan/editsort/`, params })


// 活动管理
export const douyinActivitymanage = params => ajaxGet({ url: `douyin/activitymanage/`, params })
// 活动管理 -- 新增
export const douyinActivitymanageAdd = params => ajaxPost({ url: `douyin/activitymanage/`, params })
// 活动管理 -- 编辑
export const douyinActivitymanageEdit = params => ajaxPut({ url: `douyin/activitymanage/`, params })
// 活动管理 -- 删除
export const douyinActivitymanageDelete = params => ajaxDelete({ url: `douyin/activitymanage/`, params })

/**
*热门
 * */

//商品榜单
export const douyinRemengoodsranklist = params => ajaxGet({ url: `douyin/remengoodsranklist/`, params })
// 商品榜单 新增
export const douyinRemengoodsranklistAdd = params => ajaxPost({ url: `douyin/remengoodsranklist/`, params })
// 商品榜单 编辑
export const douyinRemengoodsranklistEdit = params => ajaxPut({ url: `douyin/remengoodsranklist/`, params })
// 商品榜单 删除
export const douyinRemengoodsranklistDelete = params => ajaxDelete({ url: `douyin/remengoodsranklist/`, params })
// 商品榜单 禁用状态
export const douyinRemengoodsranklistDisablestatus = params => ajaxPut({ url: `douyin/remengoodsranklist/disablestatus/`, params })

//首页榜单
export const douyinRemenindexranklist = params => ajaxGet({ url: `douyin/remenindexranklist/`, params })
//首页榜单 新增
export const douyinRemenindexranklistAdd = params => ajaxPost({ url: `douyin/remenindexranklist/`, params })
//首页榜单 删除
export const douyinRemenindexranklistDelete = params => ajaxDelete({ url: `douyin/remenindexranklist/`, params })
// 首页榜单 禁用状态
export const douyinRemenindexranklistDisablestatus = params => ajaxPut({ url: `douyin/remenindexranklist/disablestatus/`, params })
// 首页榜单 修改排序
export const douyinRemenindexranklistEditsort = params => ajaxPut({ url: `douyin/remenindexranklist/editsort/`, params })

//榜单商品
export const douyinRemenranklistgoods = params => ajaxGet({ url: `douyin/remenranklistgoods/`, params })
//榜单商品 新增
export const douyinRemenranklistgoodsAdd = params => ajaxPost({ url: `douyin/remenranklistgoods/`, params })
//榜单商品 删除
export const douyinRemenranklistgoodsDelete = params => ajaxDelete({ url: `douyin/remenranklistgoods/`, params })
// 榜单商品 禁用状态
export const douyinRemenranklistgoodsDisablestatus = params => ajaxPut({ url: `douyin/remenranklistgoods/disablestatus/`, params })
// 榜单商品 修改排序
export const douyinRemenranklistgoodsEditsort = params => ajaxPut({ url: `douyin/remenranklistgoods/editsort/`, params })

/**
*领视频
 * */

//领视频商品分类
export const douyinLspGoodsCategory = params => ajaxGet({ url: `douyin/linshipingoodscategory/`, params })
// 领视频商品分类 新增
export const douyinLspGoodsCategoryAdd = params => ajaxPost({ url: `douyin/linshipingoodscategory/`, params })
// 领视频商品分类 编辑
export const douyinLspGoodsCategoryEdit = params => ajaxPut({ url: `douyin/linshipingoodscategory/`, params })
// 领视频商品分类 删除
export const douyinLspGoodsCategoryDelete = params => ajaxDelete({ url: `douyin/linshipingoodscategory/`, params })

//领视频商品分类
export const douyinBrandcooperate = params => ajaxGet({ url: `douyin/brandcooperate/`, params })

//领视频商品
export const douyinLspGoods = params => ajaxGet({ url: `douyin/linshipingoods/`, params })
// 领视频商品 新增
export const douyinLspGoodsAdd = params => ajaxPost({ url: `douyin/linshipingoods/`, params })
// 领视频商品 编辑
export const douyinLspGoodsEdit = params => ajaxPut({ url: `douyin/linshipingoods/`, params })
// 领视频商品 删除
export const douyinLspGoodsDelete = params => ajaxDelete({ url: `douyin/linshipingoods/`, params })

// 领视频商品 禁用状态
export const douyinLspGoodsDisablestatus = params => ajaxPut({ url: `douyin/linshipingoods/disablestatus/`, params })
// 领视频商品 修改排序
export const douyinLspGoodsEditsort = params => ajaxPut({ url: `douyin/linshipingoods/editsort/`, params })

// 领视频商品 分镜视频上传
export const douyinLspGoodsFJUpload = params => uploadImg({ url: `douyin/linshipingoods/fjVideoUpload/`, params })
// 领视频商品 上传图片
export const douyinimageUpload = params => uploadImg({ url: `douyin/linshipingoods/imageUpload/`, params })


/**
*作业管理
 * */
//作业管理
export const douyinUserjob = params => ajaxGet({ url: `douyin/userjob/`, params })

// 作业管理 审核
export const douyinUserjobPassDeny = params => ajaxPost({ url: `douyin/userjob/pass_deny/`, params })

/**
*提现管理
 * */
//提现管理
export const douyinCashout = params => ajaxGet({ url: `douyin/cashout/`, params })

// 提现管理 审核
export const douyinCashoutPassDeny = params => ajaxPost({ url: `douyin/cashout/pass_deny/`, params })


