<template>
    <div>
        <el-table :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">

            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column min-width="150" prop="app_key" label="APPKEY" ></el-table-column>
            <el-table-column min-width="150" prop="buyin_id" label="百应ID" ></el-table-column>
            <el-table-column min-width="150" prop="name" label="名称" ></el-table-column>
            <el-table-column min-width="190" prop="identity" label="身份" ></el-table-column>
<!--            <el-table-column min-width="190" prop="create_datetime" label="添加时间"></el-table-column>-->
<!--            <el-table-column min-width="190" prop="update_datetime" label="更新时间"></el-table-column>-->
            <el-table-column min-width="190" prop="expiretime" label="N小时后过期" ></el-table-column>
            <el-table-column min-width="90" label="状态" prop="status">
                <template #default="scope">
                    <el-tag v-if="scope.row.status == '正常'">正常</el-tag>
                    <el-tag v-else type="danger">过期</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
<!--                <template #header>-->
<!--                    <el-button  size="small" @click="addModule" type="primary" v-show="hasPermission(this.$route.name,'Create')">新增</el-button>-->
<!--                </template>-->
                <template #default="scope">
<!--                    <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>-->
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'reauthority')" v-show="hasPermission(this.$route.name,'Create')">重新授权</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-douyin-code-module ref="addModuleFlag" @refreshData="getData"></add-douyin-code-module>
    </div>

</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight,hasPermission} from "@/utils/util";
    import {douyinSystemdareninfoDK,douyinSystemdareninfoDelete,douyinSystemdareninfoRefreshToken} from '@/api/api'
    import AddDouyinCodeModule from "./components/addDouyinCodeModule";
    export default {
        components:{
            AddDouyinCodeModule,
            Pagination,
        },
        name:'systemDarenInfoDouyin',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{},
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'是'},
                    {id:0,name:'否'}
                ],
                tableData:[]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            handleClick(tab,e) {
                this.formInline.type = tab.props.name
                this.search()
            },
            addModule() {
                if(this.tableData.length>0){
                    this.$message.warning("已存在抖客授权，请删除后再新增")
                    return
                }
                this.$refs.addModuleFlag.addModuleFn(null,'复制链接访问授权')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='refresh') {
                    douyinSystemdareninfoRefreshToken({id:row.id,}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }
                else if(flag == "reauthority"){
                    this.$refs.addModuleFlag.addModuleFn(row,'身份授权')
                }
                else if(flag=='delete') {
                    vm.$confirm('您确定要删除选中的内容？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        douyinSystemdareninfoDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                douyinSystemdareninfoDK(this.formInline).then(res => {
                    this.loadingPage = false
                    if(res.code ==2000) {
                        this.tableData = res.data.data
                        this.pageparm.page = res.data.page;
                        this.pageparm.limit = res.data.limit;
                        this.pageparm.total = res.data.total;
                    }
                })
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
              },
            getTheTableHeight(){
                let tabSelectHeight = -8
                tabSelectHeight = this.isFull?tabSelectHeight - 100:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }
        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },
        unmounted() {
              // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>


