<!--
 * @Descripttion: 奖励门槛设置动态输入框组
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.07.03
-->
<template>
	<div>
		<el-row :gutter="20">
      <el-col :span="8">ID</el-col>
			<el-col :span="8">奖励门槛<span style="color: #a2a5ad;font-size: 12px;">(大于等于)</span></el-col>
			<el-col :span="8">奖励</el-col>
		</el-row>
		<div v-for="(item,index) in dataList" :key="index" style="display: flex;align-items: center;margin-bottom: 5px;">
      <el-input-number v-model="item.id" style="width: 100%;margin-right: 20px" placeholder="ID" :controls="false"></el-input-number>
			<el-input-number v-model="item.gmv_amount" style="width: 100%" :placeholder="placeholder1" :controls="false"></el-input-number>
			<el-input-number v-model="item.gu_amount" style="width: 100%;margin-left: 20px" :placeholder="placeholder2" :controls="false"></el-input-number>
			<el-icon @click="handleRemove(index)" style="cursor: pointer;font-size: 22px;"><delete /></el-icon>
		</div>
		<el-button type="primary" icon="Plus" :size="size" title="添加" @click="addField">{{buttonText}}</el-button>
	</div>
</template>

<script setup>
    import {ref, onMounted, watch,computed} from 'vue'

	const props = defineProps({
        modelValue: { type: [Array], default: [] },
        size: { type: String, default: "small" },
        buttonText: { type: String, default: "添加" },
        placeholder1: { type: String, default: "GMV金额" },
        placeholder2: { type: String, default: "固定金额" },
    })

	const emits = defineEmits(['update:modelValue'])

	const dataList = computed({
		get() {
			return props.modelValue
		},
		set(value) {
			emits('update:modelValue', value)
		}
    })

    function handleRemove(index){
    	  let newdatalist = dataList.value
        newdatalist.splice(index, 1)
        dataList.value = newdatalist
    }
    function addField() {
    	  let newdatalist = dataList.value
        newdatalist.push({id:(newdatalist.length+1),gmv_amount:null,gu_amount:null})
        dataList.value = newdatalist
	}

	defineExpose({

	})
</script>

<style scoped>

</style>