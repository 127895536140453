<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="商品名称：">
          <el-input size="default" v-model.trim="formInline.title" maxlength="60" clearable placeholder="商品名称"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="formInline.status" placeholder="请选择" clearable @change="search" style="width:100px">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分类" prop="category">
          <el-select v-model="formInline.category" placeholder="请选择" clearable @change="search" filterable
            style="width: 160px">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
            v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button type="primary" @click="handleEdit('', 'add')" icon="plus"
            v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>

      </el-form>
    </div>

    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tableData" ref="tableref"
        v-loading="loadingPage" style="width: 100%">
        <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
</el-table-column> -->
        <el-table-column width="200" prop="goods_info[0].product_id" label="商品ID"
          show-overflow-tooltip></el-table-column>
        <el-table-column min-width="200" prop="goods_info[0].title" label="商品名称"
          show-overflow-tooltip></el-table-column>
        <el-table-column width="90" prop="goods_info[0].cover" label="商品图片">
          <template #default="scope">
            <el-image :src="scope.row.goods_info[0].cover" :preview-src-list="[scope.row.goods_info[0].cover]"
              :preview-teleported="true" style="width: 30px;height: 30px" :onerror="defaultImg"></el-image>
          </template>
        </el-table-column>
        <el-table-column width="150" label="所属分类" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.category_name }}</span>
          </template>
        </el-table-column>
        <el-table-column width="90" prop="goods_info[0].price" label="价格">
          <template #default="scope">
            {{ scope.row.goods_info[0].price / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="70" prop="sort" label="排序"></el-table-column>
        <el-table-column width="180" prop="update_datetime" label="更新时间"></el-table-column>
        <el-table-column width="100" label="状态">
          <template #default="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
              @change="changeStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'editsort')"
              v-show="hasPermission(this.$route.name, 'EditSort')">修改排序</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
              v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
              v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <EditSortModule ref="addEditSortFlag" @refreshData="getData"></EditSortModule>
    <LyDialog v-model="isDialogShow" :fullscreen="true" v-if="isDialogShow" :title="dialogTitle" width="60%" top="30px"
      :before-close="handleDialogClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
        label-width="auto">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="视频封面" prop="video_cover">
              <LyUploadAvatar v-model="formData.video_cover" width="80px" height="80px"></LyUploadAvatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="范本视频" prop="sample_video">
              <LyUploadVideo v-model="formData.sample_video" width="150px" height="180px"></LyUploadVideo>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="领取要求" prop="require">
              <TEditor v-model="formData.require" :style="{ height: '200' }"></TEditor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择分类" prop="category">
              <el-select v-model="formData.category" placeholder="请选择" filterable style="width: 100%">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="formData.sort" placeholder="数字越大越靠前" style="width: 100%;"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="ly-title">
          添加商品
        </div>
        <el-form-item prop="goods_info">
          <el-table :data="formData.goods_info" ref="lyaddtable" border stripe>
            <el-table-column type="index" width="60" fixed="left">
              <template #header>
                <LyTableSelect button-type="primary" placement="right" :tableIndex="false" button-icon="plus"
                  size="small" :button-circle="true" ref="lymanualSelect" :apiObj="getGoodsApi"
                  @click="handleManualSelectClick" :table-width="1000" :multiple="false"
                  @confirm="handleManualConfirmClick">
                  <template #header="{ form, submit }">
                    <el-form :inline="true" :model="form">
                      <el-form-item>
                        <el-input type="text" v-model="form.title" placeholder="商品名称" style="width: 160px;"
                          clearable></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input type="text" v-model="form.product_id" placeholder="商品ID" style="width: 160px;"
                          clearable></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-select v-model="form.status" placeholder="状态" clearable style="width: 120px;">
                          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-select v-model="form.source" placeholder="来源" clearable style="width: 120px;">
                          <el-option v-for="item in sourceList" :key="item.id" :label="item.name" :value="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item>
                                                <el-date-picker
                                                    size="small"
                                                    style="width:320px"
                                                    v-model="form.timerange"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="开始时间"
                                                    end-placeholder="结束时间">
                                                </el-date-picker>
                                            </el-form-item> -->
                      <el-form-item>
                        <el-button type="primary" @click="submit">查询</el-button>
                      </el-form-item>
                    </el-form>
                  </template>
                  <el-table-column prop="image" label="图片" width="80">
                    <template #default="scope">
                      <el-image :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true"
                        style="width: 30px;height: 30px"></el-image>
                    </template>
                  </el-table-column>
                  <el-table-column prop="title" label="名称" show-overflow-tooltip min-width="200"></el-table-column>
                  <el-table-column width="90" prop="price" label="价格">
                    <template #default="scope">
                      {{ scope.row.price / 100 }}
                    </template>
                  </el-table-column>
                  <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
                  <el-table-column min-width="180" prop="product_id" label="商品ID"
                    show-overflow-tooltip></el-table-column>

                </LyTableSelect>
              </template>
              <template #default="scope">
                <div class="ly-form-table-handle">
                  <span>{{ scope.$index + 1 }}</span>
                  <el-button type="danger" icon="delete" size="small" plain circle
                    @click="rowDel(scope.row, scope.$index)"></el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="商品名称" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="cover" label="商品图" width="80">
              <template #default="scope">
                <el-image :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true"
                  style="width: 40px;height: 40px"></el-image>
              </template>
            </el-table-column>
            <el-table-column width="90" prop="price" label="价格">
              <template #default="scope">
                {{ scope.row.price / 100 }}
              </template>
            </el-table-column>
            <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
            <el-table-column min-width="180" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>
          </el-table>
        </el-form-item>
        <el-tabs v-model="tabsActiveName">
          <el-tab-pane label="分镜素材" name="fjsc">
            <div class="ly-title">
              分镜素材 <el-button type="primary" size="small" @click="handelAddFenjingInfo">新增分组</el-button>
            </div>
            <div style="margin-bottom:20px;">
              <LyUploadSingleVideo :group="inputVisibleTagId" buttonText="新增视频" size="small"
                v-if="inputVisibleTagId > 0" @onSuccess="mVideoUploadSuccess"></LyUploadSingleVideo>
            </div>
            <div class="dragList">
              <Vuedraggable v-model="formData.group_info" animation="200" item-key="id" group="group_info">
                <template #item="{ element }">
                  <el-tag class="groupitem" :type="element.id === inputVisibleTagId ? 'warning' : ''" closable
                    @close="handelDelGroupInfo(element.id)" @click="handelTagChangeValue(element)">{{ element.text
                    }}</el-tag>
                </template>
              </Vuedraggable>
              <el-input class="input-new-tag" v-if="inputVisibleTag" v-model="inputValueTag" ref="saveTagInput"
                size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
              </el-input>
              <el-tag class="groupitem" :type="0 === inputVisibleTagId ? 'warning' : ''"
                @click="handelTagChangeValue2(0)">未分组素材</el-tag>
            </div>
            <Vuedraggable v-model="fjsucai_select" @dragend="videoDragEnd" animation="200" item-key="id"
              group="group_video" style="display: flex;overflow: auto;flex-wrap: wrap;"
              v-if="fjsucai_select.length > 0">
              <template #item="{ element, index }">
                <div class="videoitem">
                  <video style="width: 200px;height: 260px;" :autoplay="false" :src="element.video"
                    :poster="element.cover" controls></video>
                  <div style="display:flex;justify-content: space-between;margin-top:5px;align-items: center;">
                    <el-select v-model="element.group" placeholder="请选择分组" size="small" style="width:150px"
                      @change="handleSelectFJscVideoGroupChange(element, index)">
                      <el-option v-for="item in formData.group_info" :key="item.id" :label="item.text"
                        :value="item.id"></el-option>
                    </el-select>
                    <span class="table-operate-btn" @click="handelDelFJscVideo(element.id)">删除</span>
                  </div>
                </div>
              </template>
            </Vuedraggable>
          </el-tab-pane>
          <el-tab-pane label="图片素材" name="tpsc">
            <div class="ly-title">
              图片素材 <el-button type="primary" size="small" @click="handelAddGroupInfo">新增分组</el-button>
            </div>
            <div style="margin-bottom:20px;">
              <LyUploadSingleImage :group="imagesVisibleTagId" buttonText="新增图片" size="small"
                v-if="imagesVisibleTagId > 0" @onSuccess="mImageUploadSuccess"></LyUploadSingleImage>
            </div>
            <div class="dragList">
              <Vuedraggable v-model="formData.image_group_info" animation="200" item-key="id" group="image_group_info">
                <template #item="{ element }">
                  <el-tag class="groupitem" :type="element.id === imagesVisibleTagId ? 'warning' : ''" closable
                    @close="handelImageDelGroupInfo(element.id)" @click="handelImageTagChangeValue(element)">{{
    element.text
  }}</el-tag>
                </template>
              </Vuedraggable>
              <el-input class="input-new-tag" v-if="imageVisibleTag" v-model="imageValueTag" ref="saveTagInput"
                size="small" @keyup.enter.native="handleImageConfirm" @blur="handleImageConfirm">
              </el-input>
              <el-tag class="groupitem" :type="0 === imagesVisibleTagId ? 'warning' : ''"
                @click="handelImageTagChangeValue2(0)">未分组素材</el-tag>
            </div>
            <Vuedraggable v-model="imageSucai_select" @dragend="videoDragEnd" animation="200" item-key="id"
              group="group_video" style="display: flex;overflow: auto;flex-wrap: wrap;"
              v-if="imageSucai_select.length > 0">
              <template #item="{ element, index }">
                <div class="videoitem">
                  <img :src="element.image" style="width: 200px;height: 260px;" alt="">
                  <!-- <video style="width: 200px;height: 260px;" :autoplay="false" :src="element.video"
                    :poster="element.cover" controls></video> -->
                  <div style="display:flex;justify-content: space-between;margin-top:5px;align-items: center;">
                    <el-select v-model="element.group" placeholder="请选择分组" size="small" style="width:150px"
                      @change="handleSelectFJscVideoGroupChange(element, index)">
                      <el-option v-for="item in formData.image_group_info" :key="item.id" :label="item.text"
                        :value="item.id"></el-option>
                    </el-select>
                    <span class="table-operate-btn" @click="handelImageDelFJscVideo(element.id)">删除</span>
                  </div>
                </div>
              </template>
            </Vuedraggable>
          </el-tab-pane>
          <el-tab-pane label="视频脚本" name="spjb">
            <div class="ly-title">
              视频脚本 <el-button type="primary" size="small" @click="handelAddScriptInfo">新增脚本</el-button>
            </div>
            <Vuedraggable v-model="formData.video_script" @dragend="videoScriptDragEnd" animation="200" item-key="id"
              group="group_video_script" handle=".drag-option">
              <template #item="{ element: item, index }">
                <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom:10px;">
                  <el-icon class="drag-option" size="20">
                    <Rank />
                  </el-icon>
                  <el-input type="textarea" :rows="1" v-model="item.title" maxlength="100" show-word-limit clearable
                    placeholder="请输入标题" style="width:100%;margin-right:20px;margin-left:5px;"></el-input>
                  <el-input type="textarea" :rows="1" v-model="item.text" maxlength="200" show-word-limit clearable
                    placeholder="请输入文案内容" style="width:100%"></el-input>
                  <span class="table-operate-btn" style="width:80px;text-align:right;"
                    @click="handelDelScriptInfo(index)">删除</span>
                </div>
              </template>
            </Vuedraggable>
          </el-tab-pane>
        </el-tabs>

      </el-form>
      <template #footer>
        <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </LyDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight, deepClone } from "@/utils/util";
import { douyinLspGoods, douyinProductmanage, douyinLspGoodsAdd, douyinLspGoodsEdit, douyinLspGoodsDelete, douyinLspGoodsDisablestatus, douyinLspGoodsEditsort, douyinLspGoodsCategory } from '@/api/api'
import EditSortModule from "./components/editSortModule.vue"
import LyTableSelect from "@/components/lyTableSelect";
import LyDialog from "@/components/dialog/dialog2";
import TEditor from '@/components/TEditor';
import LyUploadAvatar from "@/components/upload/avatar";
import LyUploadVideo from "@/components/upload/video";
import Vuedraggable from "vuedraggable";
import LyUploadSingleVideo from "@/components/upload/single-video";
import LyUploadSingleImage from "@/components/upload/images";

export default {
  components: {
    Pagination,
    EditSortModule,
    LyTableSelect,
    LyDialog,
    TEditor,
    LyUploadAvatar,
    LyUploadVideo,
    Vuedraggable,
    LyUploadSingleVideo,
    LyUploadSingleImage
  },
  name: 'linshipinGoodsManage',
  data () {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
      },
      defaultImg: require('../../assets/img/avatar.jpg'),
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      sourceList: [
        { id: 0, name: '精选联盟' },
        { id: 1, name: '团长活动' },
      ],
      timers: [],
      tableData: [],
      tabsActiveName: 'fjsc',
      //dialog
      loadingSave: false,
      isDialogShow: false,
      dialogTitle: '新增',
      fjsucai_select: [],//选中的分组视频
      imageSucai_select: [],//选中的分组图片
      formData: {
        group_info: [],
        fjsucai: [],
        video_script: [],
        image_group_info: [],
        image_sucai: []
      },
      categoryList: [],
      rules: {
        category: [
          { required: true, message: '请选择分类', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ],
        video_cover: [
          { required: true, message: '请上传视频封面', trigger: 'blur' }
        ],
        sample_video: [
          { required: true, message: '请上传范本视频', trigger: 'blur' }
        ],

      },
      inputVisibleTagId: 0,
      imagesVisibleTagId: 0,
      inputValueTag: '',
      inputVisibleTag: false,
      imageValueTag: '',
      imageVisibleTag: false
    }
  },

  methods: {
    // 表格序列号
    getIndex ($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull () {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    videoDragEnd (e) {
      let oldVideoList = deepClone(this.formData.fjsucai)
      this.fjsucai_select.forEach(item => {
        oldVideoList.forEach((item1, index1) => {
          if (item1.id == item.id) {
            oldVideoList.splice(index1, 1)
          }
        })
      })
      this.fjsucai_select.forEach(item => {
        oldVideoList.push(item)
      })
      this.formData.fjsucai = oldVideoList

    },
    videoScriptDragEnd (e) {

    },
    mVideoUploadSuccess (items) {
      const newdatas = deepClone(items)

      let maxValue = 0
      let data = []
      if (this.formData.fjsucai && this.formData.fjsucai.length > 0) {
        this.formData.fjsucai.forEach(item => {
          data.push(item.id)
        })
        maxValue = this.getArrayMaxValue(data)
      } else {
        maxValue = 0
      }
      if (newdatas.length > 0) {
        newdatas.forEach((item, index) => {
          let newMaxValue = maxValue + index + 1
          item.id = newMaxValue
          this.fjsucai_select.push(item)
          this.formData.fjsucai.push(item)
        })
      }
    },
    mImageUploadSuccess (items) {
      const newdatas = deepClone(items)
      console.log(items);
      let maxValue = 0
      let data = []
      if (this.formData.image_sucai && this.formData.image_sucai.length > 0) {
        this.formData.image_sucai.forEach(item => {
          data.push(item.id)
        })
        maxValue = this.getArrayMaxValue(data)
      } else {
        maxValue = 0
      }
      if (newdatas.length > 0) {
        newdatas.forEach((item, index) => {
          let newMaxValue = maxValue + index + 1
          item.id = newMaxValue
          this.imageSucai_select.push(item)
          this.formData.image_sucai.push(item)
        })
      }
    },
    handleSelectFJscVideoGroupChange (item, index) {
      if (item.group != this.inputVisibleTagId) {
        this.fjsucai_select.splice(index, 1)
      }
      this.formData.fjsucai.forEach((item1, index1) => {
        if (item1.id == item.id) {
          item1.group = item.group
        }
      })
    },
    getArrayMaxValue (arr) {
      var max = arr[0];
      for (var i = 1; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i];
        }
      }
      return max
    },
    handelAddScriptInfo () {
      if (!this.formData.video_script) {
        this.formData.video_script = []
      }
      this.formData.video_script.push({ title: "", text: "" })
    },
    handelDelScriptInfo (index) {
      this.formData.video_script.splice(index, 1)
    },
    handelAddFenjingInfo () {
      let maxValue = 0
      let data = []
      if (this.formData.group_info && this.formData.group_info.length > 0) {
        this.formData.group_info.forEach(item => {
          data.push(item.id)
        })
        maxValue = this.getArrayMaxValue(data)
      } else {
        maxValue = 0
      }
      let newId = maxValue + 1
      this.formData.group_info.push({ id: newId, text: "新分组" + newId })
    },
    handelAddGroupInfo () {
      let maxValue = 0
      let data = []
      if (this.formData.image_group_info && this.formData.image_group_info.length > 0) {
        this.formData.image_group_info.forEach(item => {
          data.push(item.id)
        })
        maxValue = this.getArrayMaxValue(data)
      } else {
        maxValue = 0
      }
      let newId = maxValue + 1
      this.formData.image_group_info.push({ id: newId, text: "新分组" + newId })
    },
    handelDelFJscVideo (id) {
      let vm = this
      vm.$confirm('确定要删除该视频吗？', '提醒', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.formData.fjsucai.forEach((item, index) => {
          if (item.id === id) {
            vm.formData.fjsucai.splice(index, 1)
          }
        })
        vm.fjsucai_select.forEach((item, index) => {
          if (item.id === id) {
            vm.fjsucai_select.splice(index, 1)
          }
        })
      }).catch(() => {

      })
    },
    handelImageDelFJscVideo (id) {
      let vm = this
      vm.$confirm('确定要删除该视频吗？', '提醒', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.formData.image_sucai.forEach((item, index) => {
          if (item.id === id) {
            vm.formData.image_sucai.splice(index, 1)
          }
        })
        vm.imageSucai_select.forEach((item, index) => {
          if (item.id === id) {
            vm.imageSucai_select.splice(index, 1)
          }
        })
      }).catch(() => {

      })
    },
    handelDelGroupInfo (id) {
      let vm = this
      vm.$confirm('确定要删除该分组吗？', '提醒', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.formData.group_info.forEach((item, index) => {
          if (item.id === id) {
            vm.formData.group_info.splice(index, 1)
          }
        })
        vm.formData.fjsucai.forEach((item, index) => {
          if (item.group == id) {
            item.group = ''
          }
        })
        if (vm.inputVisibleTagId == id) {
          vm.inputVisibleTagId = 0
          vm.fjsucai_select = []
          vm.formData.fjsucai.forEach((its, index) => {
            if (its.group == "" || its.group == undefined) {
              vm.fjsucai_select.push(its)
            }
          })
        }

      }).catch(() => {

      })
    },
    handelImageDelGroupInfo (id) {
      let vm = this
      vm.$confirm('确定要删除该分组吗？', '提醒', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.formData.image_group_info.forEach((item, index) => {
          if (item.id === id) {
            vm.formData.image_group_info.splice(index, 1)
          }
        })
        vm.formData.image_sucai.forEach((item, index) => {
          if (item.group == id) {
            item.group = ''
          }
        })
        if (vm.imagesVisibleTagId == id) {
          vm.imagesVisibleTagId = 0
          vm.imageSucai_select = []
          vm.formData.image_sucai.forEach((its, index) => {
            if (its.group == "" || its.group == undefined) {
              vm.imageSucai_select.push(its)
            }
          })
        }

      }).catch(() => {

      })
    },
    handleInputConfirm () {
      let inputValue = this.inputValueTag;
      // 去空格
      inputValue = inputValue.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
      if (inputValue == '') {
        this.inputVisibleTag = false
        return
      }
      //修改原有值
      this.formData.group_info.forEach((item, index) => {
        if (item.id === this.inputVisibleTagId) {
          item.text = inputValue
        }
      })
      this.inputVisibleTag = false
      this.inputValueTag = ''
      // this.inputVisibleTagId = ""
    },
    handleImageConfirm () {
      let imageValue = this.imageValueTag;
      console.log(this.imageValueTag);
      // 去空格
      imageValue = imageValue.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
      if (imageValue == '') {
        this.imageVisibleTag = false
        return
      }
      //修改原有值
      this.formData.image_group_info.forEach((item, index) => {
        if (item.id === this.imageVisibleTagId) {
          item.text = imageValue
        }
      })
      this.imageVisibleTag = false
      this.imageValueTag = ''
      // this.imageVisibleTagId = ""
    },
    handelTagChangeValue (item) {
      this.inputVisibleTag = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
      this.inputValueTag = item.text
      this.inputVisibleTagId = item.id
      this.fjsucai_select = []
      this.formData.fjsucai.forEach((its, index) => {
        if (its.group == item.id) {
          this.fjsucai_select.push(its)
        }
      })
    },
    handelImageTagChangeValue (item) {
      this.imageVisibleTag = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
      this.imageValueTag = item.text
      this.imagesVisibleTagId = item.id
      this.imageSucai_select = []
      this.formData.image_sucai.forEach((its, index) => {
        if (its.group == item.id) {
          this.imageSucai_select.push(its)
        }
      })
    },
    handelTagChangeValue2 (id) {
      this.inputVisibleTagId = 0
      this.fjsucai_select = []
      this.formData.fjsucai.forEach((its, index) => {
        if (its.group == "" || its.group == undefined) {
          this.fjsucai_select.push(its)
        }
      })
    },
    handelImageTagChangeValue2 (id) {
      this.imagesVisibleTagId = 0
      this.imageSucai_select = []
      this.formData.image_sucai.forEach((its, index) => {
        if (its.group == "" || its.group == undefined) {
          this.imageSucai_select.push(its)
        }
      })
    },
    handleDialogClose () {
      this.isDialogShow = false
      this.formData = {
        group_info: [],
        fjsucai: [],
        video_script: []
      }
      this.inputVisibleTagId = 0
      this.inputValueTag = ""
      this.inputVisibleTag = false
      this.fjsucai_select = []
      this.tabsActiveName = 'fjsc'
    },
    /** 添加表格操作 */
    rowDel (row, index) {
      this.formData.goods_info.splice(index, 1)
    },
    getcategoryList () {
      douyinLspGoodsCategory({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.categoryList = res.data.data
        }
      })
    },
    getGoodsApi () {
      return douyinProductmanage
    },
    handleManualSelectClick () {
      this.$refs.lymanualSelect.handleChooseClick()
    },
    handleManualConfirmClick (items) {
      //去重赋值
      this.formData.goods_info[0] = items
    },
    changeStatus (row) {
      let vm = this
      let flat = row.status
      row.status = !row.status
      let temp_is_allow = 1
      vm.$confirm('确定修改状态吗？', '提醒', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        flat ? row.status = true : row.status = false
        if (row.status) {
          temp_is_allow = 1
        } else {
          temp_is_allow = 0
        }
        douyinLspGoodsDisablestatus({ id: row.id }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }).catch(() => {

      })
    },
    handleEdit (row, flag) {
      if (flag == 'editsort') {
        this.$refs.addEditSortFlag.addModuleFn(row, '修改排序', douyinLspGoodsEditsort)
      } else if (flag == 'add') {
        this.formData.goods_info = []
        this.isDialogShow = true
      }
      else if (flag == 'edit') {
        this.formData = deepClone(row)
        this.isDialogShow = true
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          douyinLspGoodsDelete({ id: row.id }).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },
    submitData () {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (!param.video_script) {
            param.video_script = []
          }
          if (this.formData.id) {
            param.goods_info.map(item => {
              param.inner_product_id = item.id
              param.product_id = item.product_id
            })
            douyinLspGoodsEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            douyinLspGoodsAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleDialogClose()
                this.getData()
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
    callFather (parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search () {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData () {
      this.loadingPage = true
      douyinLspGoods(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange (val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize () {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight () {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }

  },
  created () {
    this.getData()
    this.getcategoryList()
  },
  mounted () {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted () {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
<style scoped>
.ly-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  font-size: 17px;
  padding-bottom: 15px;
  color: #3c4a54;
  font-weight: bold;
}

.ly-form-table-handle {
  text-align: center;
}

.ly-form-table-handle span {
  display: inline-block;
}

.ly-form-table-handle button {
  display: none;
}

.hover-row .ly-form-table-handle span {
  display: none;
}

.hover-row .ly-form-table-handle button {
  display: inline-block;
}

.dragList {
  overflow: auto;
}

.dragList .sortable-ghost {
  opacity: 0.5;
}

.groupitem {
  cursor: move;
  float: left;
  text-align: center;
  margin: 0 15px 15px 0;
  border: 1px solid #e6e6e6;
}

.videoitem {
  cursor: move;
  text-align: center;
  margin: 0 15px 15px 0;
  width: 200px;
}

.drag-option {
  cursor: move;
}
</style>
