<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="活动名称：">
          <el-input size="default" v-model.trim="formInline.task_name" maxlength="60" clearable placeholder="活动名称"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="活动ID：">
          <el-input size="default" v-model.trim="formInline.task_id" maxlength="60" clearable placeholder="活动ID"
            @change="search" style="width:160px"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="formInline.status" placeholder="请选择" clearable @change="search" style="width:100px">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
            v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button @click="syncTZGoods" type="warning" icon="refresh"
            v-show="hasPermission(this.$route.name, 'Sync')">同步赏金任务</el-button></el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tableData" ref="tableref"
        v-loading="loadingPage" style="width: 100%">
        <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
</el-table-column> -->
        <el-table-column width="80" prop="id" label="ID"></el-table-column>
        <el-table-column width="160" prop="task_id" label="任务ID" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="200" prop="task_name" label="任务名称" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column width="90" prop="conver" label="任务图片">
                    <template #default="scope">
                        <el-image  :src="scope.row.conver" :preview-src-list="[scope.row.conver]" :preview-teleported="true" style="width: 30px;height: 30px" ></el-image>
                    </template>
                </el-table-column> -->
        <el-table-column width="80" prop="sort" label="排序"></el-table-column>
        <el-table-column width="120" prop="apply_cnt" label="已领取人数"></el-table-column>
        <el-table-column width="120" prop="promote_cnt" label="已推广人数"></el-table-column>
        <el-table-column width="130" prop="gmv" label="支付GMV">
          <template #default="scope">
            {{ (scope.row.gmv / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column width="100" prop="order_cnt" label="总订单量"></el-table-column>
        <el-table-column width="110" prop="can_apply" label="是否可报名">
          <template #default="scope">
            <el-tag v-if="scope.row.can_apply">是</el-tag>
            <el-tag v-else type="danger">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="110" prop="task_status_name" label="任务状态"></el-table-column>
        <el-table-column width="100" label="状态">
          <template #default="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
              @change="changeStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'editsort')"
              v-show="hasPermission(this.$route.name, 'EditSort')">修改排序</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'editcolonesort')">编辑</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <EditSortModule ref="addEditSortFlag" @refreshData="getData"></EditSortModule>
    <editcoloneModule ref="addEditcoloneSortFlag" @refreshData="getData"></editcoloneModule>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, deepClone, getTableHeight } from "@/utils/util";
import {
  douyinAwardTask,
  douyinAwardTaskDisablestatus,
  douyinAwardTaskEditsort,
  douyinAwardTaskSyncColonelGoods,
  douyinDyAwardTask
} from '@/api/api'
import EditSortModule from "./components/editSortModule.vue"
import editcoloneModule from "./components/editcoloneModule.vue"
import LyDialog from "@/components/dialog/dialog2.vue";
export default {
  components: {
    LyDialog,
    Pagination,
    EditSortModule,
    editcoloneModule
  },
  name: 'colonelAwardTaskManage',
  data () {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
      },
      defaultImg: require('../../assets/img/avatar.jpg'),
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      sourceList: [
        { id: 0, name: '精选联盟' },
        { id: 1, name: '团长活动' },
      ],
      timers: [],
      tableData: [],
      //dialog
      loadingSave: false,
      isDialogShow: false,
      dialogTitle: '新增',
      formData: {},
      rules: {
      }
    }
  },

  methods: {
    // 表格序列号
    getIndex ($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull () {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    handleDialogClose () {
      this.isDialogShow = false
      this.formData = {}
    },
    submitData () {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          douyinProductmanageEditAttribute(param).then(res => {
            this.loadingSave = false
            if (res.code == 2000) {
              this.$message.success(res.msg)
              this.handleDialogClose()
              this.getData()
            } else {
              this.$message.warning(res.msg)
            }
          })

        }
      })
    },
    syncTZGoods () {
      let vm = this
      vm.$confirm('同步赏金任务比较耗时，同步期间不要做其他操作！！！，您确定要同步吗？', {
        closeOnClickModal: false,
        type: 'warning',
      }).then(res => {
        vm.loadingPage = true
        douyinAwardTaskSyncColonelGoods().then(res => {
          vm.loadingPage = false
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }).catch(() => {

      })
    },
    changeStatus (row) {
      let vm = this
      let flat = row.status
      row.status = !row.status
      let temp_is_allow = 1
      vm.$confirm('确定修改状态吗？', '提醒', {
        closeOnClickModal: false
      }).then(res => {
        flat ? row.status = true : row.status = false
        if (row.status) {
          temp_is_allow = 1
        } else {
          temp_is_allow = 0
        }
        douyinAwardTaskDisablestatus({ id: row.id }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }).catch(() => {

      })
    },
    handleEdit (row, flag) {
      if (flag == 'editsort') {
        this.$refs.addEditSortFlag.addModuleFn(row, '修改排序', douyinAwardTaskEditsort)
      } else if (flag == "editcolonesort") {
        this.$refs.addEditcoloneSortFlag.addModuleFn(row, '编辑内容', douyinDyAwardTask)
      } else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },
    callFather (parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search () {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData () {
      this.loadingPage = true
      douyinAwardTask(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange (val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize () {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight () {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }

  },
  created () {
    this.getData()
  },
  mounted () {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted () {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
