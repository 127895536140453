<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="商品名称：">
                    <el-input size="default" v-model.trim="formInline.title" maxlength="60"  clearable placeholder="商品名称" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择榜单" prop="ranktype">
                    <el-select v-model="formInline.ranktype" placeholder="请选择" clearable @change="search"  filterable style="width: 160px">
                        <el-option
                            v-for="item in categoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button type="primary" @click="handleEdit('','add')" icon="plus" v-show="hasPermission(this.$route.name,'Create')">新增</el-button></el-form-item>

            </el-form>
        </div>

        <div class="table">
            <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column min-width="150" label="所属榜单" show-overflow-tooltip>
                    <template #default="scope">
                        <span>{{scope.row.ranktype_info.name + ">"+scope.row.ranktype_info.parent_name}}</span>
                     </template>
                </el-table-column>
                <el-table-column width="200" prop="goods_info.product_id" label="商品ID" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="200" prop="goods_info.title" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="90" prop="goods_info.cover" label="商品图片">
                    <template #default="scope">
                        <el-image  :src="scope.row.goods_info.cover" :preview-src-list="[scope.row.goods_info.cover]" :preview-teleported="true" style="width: 30px;height: 30px" :onerror="defaultImg" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column width="90" prop="goods_info.price" label="价格">
                    <template #default="scope">
                        {{ scope.row.goods_info.price/100 }}
                    </template>
                </el-table-column>
                <el-table-column width="70" prop="sort" label="排序"></el-table-column>
                <el-table-column width="180" prop="update_datetime" label="更新时间"></el-table-column>
                <el-table-column width="100" label="状态">
                    <template #default="scope">
                       <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'editsort')" v-show="hasPermission(this.$route.name,'EditSort')">修改排序</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <EditSortModule ref="addEditSortFlag" @refreshData="getData"></EditSortModule>
        <LyDialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="60%" top="30px" :before-close="handleDialogClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="选择榜单" prop="ranktype">
                            <el-select v-model="formData.ranktype" placeholder="请选择"  filterable style="width: 100%">
                                <el-option
                                    v-for="item in categoryList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="排序" prop="sort">
                            <el-input-number v-model="formData.sort" placeholder="数字越大越靠前" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    添加商品
                </div>
                <el-form-item prop="data">
                    <el-table :data="formData.data" ref="lyaddtable" border stripe>
                        <el-table-column type="index" width="60" fixed="left">
                            <template #header>
                                <LyTableSelect button-type="primary" placement="right" :tableIndex="false" button-icon="plus" size="small" :button-circle="true" ref="lymanualSelect" :apiObj="getGoodsApi" @click="handleManualSelectClick" :table-width="1000" multiple @confirm="handleManualConfirmClick">
                                    <template #header="{form, submit}">
                                        <el-form :inline="true" :model="form">
                                            <el-form-item>
                                                <el-input type="text" v-model="form.title" placeholder="商品名称" style="width: 160px;" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-input type="text" v-model="form.product_id" placeholder="商品ID" style="width: 160px;" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-select  v-model="form.status" placeholder="状态" clearable style="width: 120px;">
                                                    <el-option
                                                        v-for="item in statusList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item>
                                                <el-select  v-model="form.source" placeholder="来源" clearable style="width: 120px;">
                                                    <el-option
                                                        v-for="item in sourceList"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <!-- <el-form-item>
                                                <el-date-picker
                                                    size="small"
                                                    style="width:320px"
                                                    v-model="form.timerange"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="开始时间"
                                                    end-placeholder="结束时间">
                                                </el-date-picker>
                                            </el-form-item> -->
                                            <el-form-item>
                                                <el-button type="primary" @click="submit">查询</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </template>
                                    <el-table-column prop="image" label="图片"  width="80">
                                        <template #default="scope">
                                            <el-image  :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true" style="width: 30px;height: 30px"></el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="title" label="名称" show-overflow-tooltip min-width="200"></el-table-column>
                                    <el-table-column width="90" prop="price" label="价格">
                                        <template #default="scope">
                                            {{ scope.row.price/100 }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
                                    <el-table-column min-width="180" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>
                                    
                                </LyTableSelect>
                            </template>
                            <template #default="scope">
                                <div class="ly-form-table-handle">
                                    <span>{{scope.$index + 1}}</span>
                                    <el-button type="danger" icon="delete" size="small" plain circle @click="rowDel(scope.row, scope.$index)"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="商品名称" min-width="200" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="cover" label="商品图" width="80">
                            <template #default="scope">
                                <el-image  :src=scope.row.cover :preview-src-list="[scope.row.cover]" :preview-teleported="true" style="width: 40px;height: 40px"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column width="90" prop="price" label="价格">
                            <template #default="scope">
                                {{ scope.row.price/100 }}
                            </template>
                        </el-table-column>
                        <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
                        <el-table-column min-width="180" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </LyDialog>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import {douyinRemenranklistgoods,douyinProductmanage,douyinRemenranklistgoodsAdd,douyinRemenranklistgoodsDelete,douyinRemenranklistgoodsDisablestatus,douyinRemenranklistgoodsEditsort,douyinRemengoodsranklist} from '@/api/api'
    import EditSortModule from "./components/editSortModule.vue"
    import LyTableSelect from "@/components/lyTableSelect";
    import LyDialog from "@/components/dialog/dialog2";
    export default {
        components:{
            Pagination,
            EditSortModule,
            LyTableSelect,
            LyDialog
        },
        name:'remenRankListGoodsManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                sourceList:[
                    {id:0,name:'精选联盟'},
                    {id:1,name:'团长活动'},
                ],
                timers:[],
                tableData:[],
                //dialog
                loadingSave:false,
                isDialogShow:false,
                dialogTitle:'新增',
                formData:{},
                categoryList:[],
                rules:{
                    ranktype: [
                        {required: true, message: '请选择榜单',trigger: 'blur'}
                    ],
                    sort: [
                        {required: true, message: '请输入排序',trigger: 'blur'}
                    ]
                }
            }
        },

        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            handleDialogClose(){
                this.isDialogShow = false
                this.formData = {}
            },
             /** 添加表格操作 */
            rowDel(row, index){
                this.formData.data.splice(index, 1)
            },
            getcategoryList() {
                douyinRemengoodsranklist({page:1,limit:999,parent:1}).then(res=>{
                    if(res.code == 2000){
                        this.categoryList = res.data.data
                    }
                })
            },
            getGoodsApi() {
                return douyinProductmanage
            },
            handleManualSelectClick(){
                this.$refs.lymanualSelect.handleChooseClick()
            },
            handleManualConfirmClick(items){
                //去重赋值
                if(items.length>0){
                    items.forEach(item=>{
                        //如果不存在返回值为-1(去重)
                        if(this.formData.data.findIndex(item1=>item1.id === item.id ) == -1){
                            this.formData.data.push(item)
                        }
                    })
                }
            },
            changeStatus(row) {
                let vm = this
                let flat = row.status
                row.status = !row.status
                let temp_is_allow = 1
                vm.$confirm('确定修改状态吗？','提醒',{
                        closeOnClickModal:false
                    }).then(res=>{
                        flat ? row.status = true : row.status = false
                        if(row.status){
                            temp_is_allow = 1
                        }else{
                            temp_is_allow = 0
                        }
                        douyinRemenranklistgoodsDisablestatus({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
            },
            handleEdit(row,flag) {
                if(flag=='editsort'){
                    this.$refs.addEditSortFlag.addModuleFn(row,'修改排序',douyinRemenranklistgoodsEditsort)
                }else if(flag == 'add'){
                    this.formData.data = []
                    this.isDialogShow = true
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        douyinRemenranklistgoodsDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.getData()
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        douyinRemenranklistgoodsAdd(param).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleDialogClose()
                                this.getData()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })

                    }
                })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                douyinRemenranklistgoods(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }

        },
        created() {
            this.getData()
            this.getcategoryList()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },

        unmounted() {
             // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>
<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .ly-form-table-handle {
        text-align: center;
    }
	.ly-form-table-handle span {
        display: inline-block;
    }
	.ly-form-table-handle button {
        display: none;
    }
    .hover-row .ly-form-table-handle span {
        display: none;
    }
	.hover-row .ly-form-table-handle button {
        display: inline-block;
    }
</style>
