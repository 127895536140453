<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="商品名称：">
                    <el-input size="default" v-model.trim="formInline.title" maxlength="60"  clearable placeholder="商品名称" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="商品ID：">
                    <el-input size="default" v-model.trim="formInline.product_id" maxlength="60"  clearable placeholder="商品ID" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="来源：">
                    <el-select v-model="formInline.source" placeholder="请选择" clearable @change="search" style="width:120px">
                        <el-option
                                v-for="item in sourceList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更新时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="syncTZGoods" type="warning" icon="refresh" v-show="hasPermission(this.$route.name,'SyncTZGoods')">同步团长活动商品</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column width="80" prop="id" label="ID"></el-table-column>
                <el-table-column width="200" prop="product_id" label="商品ID" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="200" prop="title" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="90" prop="cover" label="商品图片">
                    <template #default="scope">
                        <el-image  :src="scope.row.cover" :preview-src-list="[scope.row.cover]" :preview-teleported="true" style="width: 30px;height: 30px" :onerror="defaultImg" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column width="90" prop="price" label="价格">
                    <template #default="scope">
                        {{ scope.row.price/100 }}
                    </template>
                </el-table-column>
                <el-table-column width="70" prop="sort" label="排序"></el-table-column>
                <el-table-column width="180" prop="update_datetime" label="更新时间"></el-table-column>
                <el-table-column width="90" prop="today_share_nums" label="今日转发"></el-table-column>
                <el-table-column width="90" prop="last_share_nums" label="昨日转发"></el-table-column>
                <el-table-column width="80" prop="share_nums" label="总转发"></el-table-column>
                <el-table-column width="110" prop="source_name" label="来源"></el-table-column>
                <el-table-column width="100" label="状态">
                    <template #default="scope">
                       <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="100" label="是否计算奖励">
                    <template #default="scope">
                       <el-switch
                            v-model="scope.row.is_calc_award"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeCalcStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'editsort')" v-show="hasPermission(this.$route.name,'EditSort')">修改排序</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑属性</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <EditSortModule ref="addEditSortFlag" @refreshData="getData"></EditSortModule>
        <LyDialog v-model="isDialogShow" v-if="isDialogShow" :title="dialogTitle" width="60%" top="30px" :before-close="handleDialogClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="top" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="活动开始时间" prop="begin_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.begin_time"
                                type="date"
                                placeholder="活动开始时间"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="活动结束时间" prop="end_time">
                            <el-date-picker
                                style="width: 100%"
                                v-model="formData.end_time"
                                type="date"
                                placeholder="活动结束时间"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="样品库存" prop="sample_stock">
                            <el-input-number v-model="formData.sample_stock" placeholder="请输入样品库存" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="领样要求：>=近30天橱窗销量" prop="limit_d30_sales">
                            <el-input-number v-model="formData.limit_d30_sales" placeholder="领样要求：近30天橱窗销量" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleDialogClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </LyDialog>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats, deepClone, getTableHeight} from "@/utils/util";
    import {
        douyinProductmanage,
        douyinProductmanageDisablestatus,
        douyinProductmanageDisablecalcaward,
        douyinProductmanageSyncColonelGoods,
        douyinProductmanageEditsort,
        douyinProductmanageEditAttribute
    } from '@/api/api'
    import EditSortModule from "./components/editSortModule.vue"
    import LyDialog from "@/components/dialog/dialog2.vue";
    export default {
        components:{
            LyDialog,
            Pagination,
            EditSortModule
        },
        name:'dyGoodsManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                sourceList:[
                    {id:0,name:'精选联盟'},
                    {id:1,name:'团长活动'},
                ],
                timers:[],
                tableData:[],
                //dialog
                loadingSave:false,
                isDialogShow:false,
                dialogTitle:'新增',
                formData:{},
                rules:{
                }
            }
        },

        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            handleDialogClose(){
                this.isDialogShow = false
                this.formData = {}
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        douyinProductmanageEditAttribute(param).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleDialogClose()
                                this.getData()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })

                    }
                })
            },
            syncTZGoods(){
                let vm = this
                vm.$confirm('同步商品比较耗时，同步期间不要做其他操作！！！，您确定要同步吗？',{
                    closeOnClickModal:false,
                    type: 'warning',
                }).then(res=>{
                    vm.loadingPage = true
                    douyinProductmanageSyncColonelGoods().then(res=>{
                        vm.loadingPage = false
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{

                })
            },
            changeStatus(row) {
                let vm = this
                let flat = row.status
                row.status = !row.status
                let temp_is_allow = 1
                vm.$confirm('确定修改状态吗？','提醒',{
                        closeOnClickModal:false
                    }).then(res=>{
                        flat ? row.status = true : row.status = false
                        if(row.status){
                            temp_is_allow = 1
                        }else{
                            temp_is_allow = 0
                        }
                        douyinProductmanageDisablestatus({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
            },
            changeCalcStatus(row) {
                let vm = this
                let flat = row.is_calc_award
                row.is_calc_award = !row.is_calc_award
                let temp_is_allow = 1
                vm.$confirm('确定修改该计算状态吗？','提醒',{
                        closeOnClickModal:false
                    }).then(res=>{
                        flat ? row.is_calc_award = true : row.is_calc_award = false
                        if(row.is_calc_award){
                            temp_is_allow = 1
                        }else{
                            temp_is_allow = 0
                        }
                        douyinProductmanageDisablecalcaward({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
            },
            handleEdit(row,flag) {
                if(flag=='editsort'){
                    this.$refs.addEditSortFlag.addModuleFn(row,'修改排序',douyinProductmanageEditsort)
                }
                else if(flag == 'edit'){
                    var tempData = deepClone(row)
                    this.formData.id = tempData.id
                    this.formData.sample_stock = tempData.sample_stock
                    this.formData.limit_d30_sales = tempData.limit_d30_sales
                    this.formData.begin_time = tempData.begin_time
                    this.formData.end_time = tempData.end_time
                    this.isDialogShow = true
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.getData()
                }
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                douyinProductmanage(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
              },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }

        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },

        unmounted() {
             // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>
