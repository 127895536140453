<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="关键词：">
                    <el-input v-model.trim="formInline.order_id" maxlength="60"  clearable placeholder="订单编号/商品ID/商品名称" @change="search" style="width:170px"></el-input>
                </el-form-item>
                <el-form-item label="用户信息：">
                    <el-input v-model.trim="formInline.user" maxlength="60"  clearable placeholder="用户昵称/电话" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="订单状态：">
                    <el-select v-model="formInline.flow_point" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单类型：">
                    <el-select v-model="formInline.order_type" placeholder="请选择" clearable @change="search" style="width:100px">
                        <el-option
                                v-for="item in orderTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
<!--        <ul class="order-static" ref="orderStatic">-->
<!--            <li>订单量：{{orderstatics.totalcount}} 单</li>-->
<!--            <li>订单金额：￥{{orderstatics.totalmoney}}</li>-->
<!--        </ul>-->
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
<!--            <el-table-column type="index" width="60" align="center" label="序号">-->
<!--                <template #default="scope">-->
<!--                    <span v-text="getIndex(scope.$index)"></span>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column width="190" prop="order_id" label="订单编号" fixed="left"></el-table-column>
            <el-table-column width="120" prop="order_type_name" label="订单类型"></el-table-column>
            <el-table-column width="160"  label="用户信息">
                <template #default="scope">
                    <div>{{scope.row.userinfo.nickname}}</div>
                    <div>{{scope.row.userinfo.mobile}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="total_pay_amount"  label="订单金额">
                <template #default="scope">
                    <div>{{((scope.row.total_pay_amount)/100).toFixed(2)}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="estimated_commission"  label="预估佣金">
                <template #default="scope">
                    <div>{{((scope.row.estimated_commission)/100).toFixed(2)}}</div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="real_commission"  label="结算佣金">
                <template #default="scope">
                    <div>{{((scope.row.real_commission)/100).toFixed(2)}}</div>
                </template>
            </el-table-column>
            <el-table-column width="220"  label="支付时间/更新时间">
                <template #default="scope">
                    <div>{{scope.row.pay_success_time}}</div>
                    <div>{{scope.row.update_time}}</div>
                </template>
            </el-table-column>
            <el-table-column width="180"  label="订单状态" prop="flow_point_name"></el-table-column>
            <el-table-column label="操作" fixed="right" width="160">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <shopping-mall-order-detail ref="shoppingMallOrderDetailFlag"></shopping-mall-order-detail>
    </div>
</template>
<script>
    import shoppingMallOrderDetail from "./components/shoppingMallOrderDetail";
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {douyinDyorder,mallGoodsOrderDelete,mallGoodsOrderstatistics} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
            shoppingMallOrderDetail,
        },
        name:'mallOrderManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                defaultImg:"this.src='"+require('../../assets/img/avatar.jpg')+"'",
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    name:'',
                    status:'',
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                orderstatics:{
                    totalmoney: 0,
                    totalcount: 0,
                },
                statusList:[
                    {id:'PAY_SUCC',name:'支付完成'},
                    {id:'REFUND',name:'退款'},
                    {id:'SETTLE',name:'支付完成'},
                    {id:'CONFIRM',name:'确认收货'},
                ],
                orderTypeList:[
                    {id:1,name:'橱窗订单'},
                    {id:2,name:'分享赚订单'},
                    {id:3,name:'0元购订单'},
                    // {id:4,name:'垫付领样'},
                ],
                timers:[],
                tableData:[],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return mallGoodsOrderDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    this.$refs.shoppingMallOrderDetailFlag.orderDetailFn(row)
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //订单金额统计获取
            getOrderstatistics(){
                mallGoodsOrderstatistics(this.formInline).then(res => {
                     if(res.code ==2000) {
                         this.orderstatics = res.data.data
                     }
                 })
                  //   .finally(() => {
                  //  this.$nextTick(() => {
                  //     this.$refs.tableref.doLayout();
                  //   });
                  // });
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                 douyinDyorder(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
        },
        created() {
            this.getData()
            // this.getOrderstatistics()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>
