<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="轮播图名称：" prop="title">
                    <el-input v-model.trim="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="轮播图：" prop="image">
                    <el-upload
                            class="avatar-uploader"
                            action=""
                            :show-file-list="false"
                            :http-request="imgUploadRequest"
                            :on-success="imgUploadSuccess"
                            :before-upload="imgBeforeUpload">
                        <img v-if="formData.image" :src="formData.image" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon" size="medium"><Plus /></el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item label="位置：" prop="type">
                    <el-select v-model="formData.type" :disabled="true" style="width: 100%">
                        <el-option :key="1" label="首页轮播图" :value="1"/>
                        <el-option :key="2" label="领视频轮播图" :value="2"/>
                        <el-option :key="3" label="赏金大厅轮播图" :value="3"/>
                        <el-option :key="4" label="本地团购轮播图" :value="4"/>
                        <el-option :key="5" label="分享赚轮播图" :value="5"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="跳转样式：" prop="link_type">
                    <el-radio-group v-model="formData.link_type">
                        <el-radio :label="1">链接</el-radio>
                        <el-radio :label="2">商品</el-radio>
                        <el-radio :label="3">富文本详情</el-radio>
                        <el-radio :label="4">小程序页面</el-radio>
                        <el-radio :label="0">不跳转</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="链接：" prop="content" v-if="formData.link_type==1">
                    <el-input v-model.trim="formData.content"></el-input>
                </el-form-item>
                <el-form-item label="商品id&product_id：" prop="content"  v-if="formData.link_type==2">
                    <el-input v-model.trim="formData.content" placeholder="格式：id&product_id"></el-input>
                </el-form-item>
                <el-form-item label="富文本信息：" prop="content" v-if="formData.link_type==3">
                    <TEditor v-model="formData.content"></TEditor>
                </el-form-item>
                <el-form-item label="选择页面：" prop="content" v-if="formData.link_type==4">
                    <el-select v-model="formData.content" style="width: 100%" placeholder="请选择小程序固定页面" clearable filterable>
                        <el-option :key="1" label="首页" value="/pages/index/index"/>
                        <el-option :key="2" label="领视频" value="/pages/video/index"/>
                        <el-option :key="2" label="热门" value="/pages/hot/index"/>
                        <el-option :key="2" label="个人中心" value="/pages/my/index"/>
                        <!-- <el-option :key="3" label="赏金大厅" value="3"/> -->
                        <!-- <el-option :key="4" label="本地团购" value="4"/> -->
                        <el-option :key="5" label="分享赚" value="/pagesA/index/index3"/>
                        <el-option :key="6" label="订单管理" value="/pagesB/my/order"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort"  :min="0" :max="9999" style="width: 100%;" placeholder="数字越大越靠前"></el-input-number>
                </el-form-item>
                <el-form-item label="状态：" prop="status" v-show="false">
                    <el-switch
                            v-model="formData.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {platformsettingsLunboimgAdd,platformsettingsLunboimgEdit,platformsettingsUploadPlatformImg} from "@/api/api";
    import {url} from '@/api/url'
    import LyDialog from "@/components/dialog/dialog";
    import TEditor from '@/components/TEditor'
    export default {
        components: {LyDialog,TEditor},
        emits: ['refreshData'],
        name: "addModule",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                peopleList:[],
                formData:{
                    title:'',
                    content:'',
                    link_type:1,
                    image:'',
                    type:'',
                    sort:1,
                    status:true
                },
                rules:{
                    image: [
                        {required: true, message: '请上传图片',trigger: 'blur'}
                    ],
                    link: [
                        {required: true, message: '请输入链接',trigger: 'blur'}
                    ],
                    title: [
                        {required: true, message: '请输入标题',trigger: 'blur'}
                    ]
                }
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addModuleFn(item,flag,activeName) {
                this.loadingTitle=flag
                this.dialogVisible=true

                this.formData=item ? item : {
                    title:'',
                    content:'',
                    link_type:1,
                    image:'',
                    type:activeName,
                    sort:1,
                    status:true
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            platformsettingsLunboimgEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            platformsettingsLunboimgAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(param) {
                var vm = this
                let obj= await platformsettingsUploadPlatformImg(param)
                if(obj.code == 2000) {
                    let res=''
                    if (obj.data.data[0].indexOf("://")>=0){
                        res = obj.data.data[0]

                    }else{
                        res = url.split('/api')[0]+obj.data.data[0]
                    }
                    vm.formData.image = res
                } else {
                    vm.$message.warning(res.msg)
                }
            },
            imgUploadSuccess(res) {
                // if (res) {
                //     this.formData.image = res.url
                // }
            }
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

