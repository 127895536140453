<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="880px" :before-close="handleClose">
            <div>
                <iframe :src="codeUrl" width="100%" height="600px" frameborder="0"></iframe>
            </div>
        </ly-dialog>
    </div>
</template>

<script>
    import {douyinSystemdareninfoCreateCode} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "addDouyinCodeModule",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                codeUrl:""
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.codeUrl = ""
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.getCodeUrl(item.id)
                }else{
                    this.getCodeUrl()
                }

            },
            getCodeUrl(id){
                var params = {}
                if(id){
                    params["id"] = id
                }
                douyinSystemdareninfoCreateCode(params).then(res=>{
                    if(res.code === 2000){
                        this.codeUrl = res.data.data
                    }else{
                        this.$message.warning(res.msg)
                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

