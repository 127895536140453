<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="用户昵称：">
          <el-input v-model.trim="formInline.nickname" maxlength="60" clearable placeholder="用户昵称" @change="search"
            style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="用户电话：">
          <el-input v-model.trim="formInline.mobile" maxlength="60" clearable placeholder="用户电话" @change="search"
            style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="订单编号：">
          <el-input v-model.trim="formInline.order_id" maxlength="60" clearable placeholder="订单编号" @change="search"
            style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="活动类型：">
          <el-select v-model="formInline.active_type" placeholder="请选择" clearable @change="search" style="width:100px">
            <el-option v-for="item in ActiveTypeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="formInline.type" placeholder="请选择" clearable @change="search" style="width:100px">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖励时间：">
          <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
            v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button></el-form-item>
      </el-form>
    </div>
    <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
      <el-table-column type="index" width="60" align="center" label="序号">
        <template #default="scope">
          <span v-text="getIndex(scope.$index)"></span>
        </template>
      </el-table-column>
      <el-table-column width="160" prop="active_type_name" label="活动类型"></el-table-column>
      <el-table-column width="150" prop="type_name" label="类型"></el-table-column>
      <el-table-column width="150" prop="userinfo.nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column width="150" prop="userinfo.mobile" label="用户电话"></el-table-column>
      <el-table-column width="250" align="center" label="结算订单">
        <template #default="scope">
          <div v-for="item, k in scope.row.order_ids" :key="k">{{ item }}</div>
          <!-- <span>{{ scope.$index.order_ids }}</span> -->
        </template>
      </el-table-column>
      <el-table-column min-width="280" prop="s_gmv" label="累计确认收货订单成交额" show-overflow-tooltip></el-table-column>
      <el-table-column min-width="280" prop="reward_amount" label="奖励金额" show-overflow-tooltip></el-table-column>
      <el-table-column min-width="180" prop="create_datetime" label="奖励时间"></el-table-column>
    </el-table>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, hasPermission } from "@/utils/util";
import { douyinMarketRewardRecord } from '@/api/api'
import { lyMixins } from "@/mixins/mixins"
export default {
  mixins: [lyMixins],
  components: {
    Pagination,
  },
  name: 'marketingRewardRecord',
  data () {
    return {
      isFull: false,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
      },
      orderstatics: {
        totalmoney: 0,
      },
      defaultImg: require('../../assets/img/avatar.jpg'),
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      timers: [],
      typeList: [
        { id: 1, name: "达到奖励" },
        { id: 2, name: "超级加码" },
        { id: 3, name: "门槛奖励" },
        { id: 4, name: "超出奖励" },
      ],
      ActiveTypeList: [
        { id: 1, name: "新人扶持" },
        { id: 2, name: "拉新赚钱" }
      ],
    }
  },
  methods: {
    setFull () {
      this.isFull = !this.isFull
    },
    // 表格序列号
    getIndex ($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    addModule () {
      // this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
    },
    handleEdit (row, flag) {
      let vm = this
      if (flag == 'audit') {
        this.$refs.cashoutauditModuleFlag.auditModuleFn(row)
      }
      else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.timers = []
        this.search()
      }
    },

    callFather (parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search () {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData () {
      this.loadingPage = true
      douyinMarketRewardRecord(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },
    timeChange (val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    /**
     * 从URL里下载文件
    */
    // 下载文件
    downloadFile (url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportData () {
      // var params = {
      //     type:3,
      //     page: 1,
      //     limit: 9999,
      // }
      // retrieveFinancestatisticsExport(params).then(res => {
      //      if(res.code ==2000) {
      //          this.downloadFile(res.data.data)
      //          //this.$message.warning(res.data.data)
      //      }
      //  })
    }
  },
  created () {
    this.getData()
  },
}
</script>
<style lang="scss">
.order-static {
  display: flex;
  font-size: 14px;
  background: var(--l-order-statics);
  padding: 10px;

  li {
    margin-right: 30px;

  }
}
</style>