<!--
 * @Descripttion: 活动奖励设置
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.07.03
-->
<template>
	<div>
		<el-form label-position="top">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item inline label="达到">
						<el-input-number v-model="dataList.dd_amount" style="width: 100%" placeholder="金额" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="奖励">
						<el-input-number v-model="dataList.reward_amount" style="width: 100%;" placeholder="金额" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="超出部分奖励超出金额(%)">
						<el-input-number v-model="dataList.cc_amount" style="width: 100%;" placeholder="百分比" :controls="false"></el-input-number>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script setup>
    import {ref, onMounted, watch,computed} from 'vue'

	const props = defineProps({
        modelValue: { type: Object, default: {}},
		size: { type: String, default: "small" },
    })

	const emits = defineEmits(['update:modelValue'])

	let dataList = ref({
		dd_amount:0,
		reward_amount:0,
		cc_amount:0,
    })

	watch(()=>dataList.value,(n,o)=>{
		emits('update:modelValue', n)
	},{deep:true})
	watch(()=>props.modelValue,(n,o)=>{
		dataList.value = props.modelValue
	},{deep:true})

	function addField() {
		dataList.value = props.modelValue
	}
	onMounted(()=>{
		dataList.value = props.modelValue
	})

	defineExpose({

	})
</script>

<style scoped>

</style>