<template>
    <div v-dialogDrag>
        <el-dialog
                :title="loadingTitle"
                v-model="dialogVisible"
                width="60%"
                top="20px"
                center
                :destroy-on-close="true"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="130px">
                <div class="ly-title">
                    基础信息
                </div>
                <el-form-item label="用户头像：">
                    <el-image :src="formData.avatar" style="width: 60px;height:60px" :onerror="defaultImg" :preview-src-list="[formData.avatar]"  :preview-teleported="true"></el-image>
                </el-form-item>
                <el-form-item label="用户昵称：" prop="nickname">
                    {{formData.nickname}}
                </el-form-item>
                <el-form-item label="电话号码：" prop="mobile">
                    {{formData.mobile}}
                </el-form-item>
                <div class="ly-title">
                    抖音授权信息
                </div>
                <el-table  border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                    <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
                    <el-table-column min-width="100" prop="avatar" label="用户头像">
                        <template #default="scope">
                            <el-image  :src="scope.row.avatar ? scope.row.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg" :preview-src-list="[scope.row.avatar]"  :preview-teleported="true"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="nickname" label="用户昵称"></el-table-column>
                    <el-table-column min-width="100" prop="mobile" label="手机号"></el-table-column>
                    <el-table-column min-width="100" label="是否默认">
                        <template #default="scope">
                            <el-tag v-if="scope.row.is_default">是</el-tag>
                            <el-tag v-else type="danger">否</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" prop="token_status.access_token" label="access_token状态" >
                        <template #default="scope">
                            <el-tag v-if="scope.row.token_status.access_token">正常</el-tag>
                            <el-tag v-else type="danger">无效</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" prop="token_status.refresh_token" label="refresh_token状态" >
                        <template #default="scope">
                            <el-tag v-if="scope.row.token_status.refresh_token">正常</el-tag>
                            <el-tag v-else type="danger">无效</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="190" prop="access_token_expire" label="access_token过期时间" ></el-table-column>
                    <el-table-column min-width="190" prop="refresh_token_expire" label="refresh_token过期时间" ></el-table-column>
                    <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                </el-table>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
    <!--            <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>-->
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import {UsersDouyinAccount} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "userDetail",
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingPage:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                formData:{
                    name:'',
                    nickname:'',
                    username:'',
                    mobile:'',
                    create_datetime:'',
                    update_datetime:'',
                    is_active:true,
                    avatar:''
                },
                tableData:[],
            }
        },
        methods:{

            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                // this.$emit('refreshData')
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.formData = deepClone(item)
                this.getData(item.id)
            },
            getData(id){
                UsersDouyinAccount({id:id}).then(res=>{
                    if(res.code === 2000){
                        this.tableData = res.data.data
                    }
                    else{
                        this.$message.warning(res.msg)
                    }
                })
            }

        }
    }
</script>
<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
</style>

