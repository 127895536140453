<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" :title="dialogTitle" :before-close="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="100px">
                <div class="ly-title">
                    基础信息
                </div>
                <el-form-item label="发布结果：">
                    {{tempdata.pub_result_name}}
                </el-form-item>
                <el-form-item label="视频链接：" v-if="tempdata.pub_result == 10">
                    {{tempdata.video_url}}
                </el-form-item>
                <el-form-item label="截图：" v-if="tempdata.pub_result == 10">
                    <div class="imgList">
                        <el-image v-for="(item,index) in tempdata.limit_img" :src="item" :preview-src-list="[item]" :preview-teleported="true" style="width: 30px;height: 30px;margin:0 15px 15px 0;"></el-image>
                    </div>
                </el-form-item>
                <el-form-item label="截图：" v-if="tempdata.pub_result == 20">
                    <div class="imgList">
                        <el-image v-for="(item,index) in tempdata.fail_img" :src="item" :preview-src-list="[item]" :preview-teleported="true" style="width: 30px;height: 30px;margin:0 15px 15px 0;"></el-image>
                    </div>
                </el-form-item>
                <el-form-item label="视频评价：" v-if="tempdata.pub_result == 10">
                    <div class="imgList">
                        <el-tag v-for="(item,index) in tempdata.judge" :preview-teleported="true" style="margin:0 15px 15px 0;">{{item}}</el-tag>
                    </div>
                </el-form-item>
                <el-form-item label="反馈意见：" v-if="tempdata.pub_result == 10">
                    {{tempdata.feedback}}
                </el-form-item>
                <el-form-item label="说明：" v-if="tempdata.pub_result == 30">
                    {{tempdata.other}}
                </el-form-item>
                <div class="ly-title">
                    审核
                </div>
                <el-form-item label="审核：" prop="action">
                    <el-radio-group v-model="formData.action" :disabled="dialogTitle=='详情'">
                        <el-radio label="pass">通过</el-radio>
                        <el-radio label="deny">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="原因：" prop="audit_remarks" v-if="formData.action==='deny'">
                    <el-input type="textarea" v-model.trim="formData.audit_remarks" maxlength="200" show-word-limit clearable placeholder="请输入" style="width:100%" :disabled="dialogTitle=='详情'"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button type="primary" @click="submitData" :loading="loadingSave">提交</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {douyinUserjobPassDeny} from "@/api/api";
    import {deepClone} from "@/utils/util";
    export default {
        name: "auditModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                tempdata:"",
                dialogTitle:"",
                formData:{
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                },
                rules:{
                    // action: [
                    //     {required: true, message: '请选择审核结果',trigger: 'blur'}
                    // ]
                },
                statusList:[
                    {id:'pass',name:"通过"},
                    {id:'deny',name:"拒绝"},
                ]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    action:'pass',
                    audit_remarks:'',
                }
            },
            auditModuleFn(item,flag) {
                this.dialogVisible=true
                this.dialogTitle = flag
                this.formData.id = item.id
                this.tempdata = deepClone(item)
                if(this.dialogTitle=="详情"){
                    if(item.audit_status == 30){
                        this.formData.action = "deny"
                    }else if(item.audit_status == 20){
                        this.formData.action = "pass"
                    }
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.action === 'deny' && this.formData.audit_remarks === ""){
                            this.$message.warning("未通过原因必填项")
                            return
                        }
                        this.loadingSave=true
                        douyinUserjobPassDeny(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
    .imgList{
        overflow: auto;
    }
</style>


