<!--
 * @Descripttion: 动态添加输入框组
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.06.27
-->
<template>
	<div>
		<div v-for="(item,index) in dataList" :key="index" style="display: flex;align-items: center;margin-bottom: 5px;">
			<el-input v-model="item.name" style="width: 100%" :placeholder="placeholder"></el-input>
			<el-icon @click="handleRemove(index)" style="cursor: pointer;font-size: 22px;"><delete /></el-icon>
		</div>
		<el-button type="primary" icon="Plus" :size="size" title="增加选项" @click="addField">{{buttonText}}</el-button>
	</div>
</template>

<script setup>
    import {ref, onMounted, watch,computed} from 'vue'

	const props = defineProps({
        modelValue: { type: [Array], default: [] },
		size: { type: String, default: "small" },
		buttonText: { type: String, default: "添加" },
		placeholder: { type: String, default: "请输入" },
    })

	const emits = defineEmits(['update:modelValue'])

	const dataList = computed({
		get() {
			return props.modelValue
		},
		set(value) {
			emits('update:modelValue', value)
		}
    })

    function handleRemove(index){
    	let newdatalist = dataList.value
		newdatalist.splice(index, 1)
		dataList.value = newdatalist
    }
    function addField() {
    	let newdatalist = dataList.value
		newdatalist.push({name:""})
		dataList.value = newdatalist
	}

	defineExpose({

	})
</script>

<style scoped>

</style>